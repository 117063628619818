@use "sass:map";

.breadcrumbs {
  margin: 0;
  display: flex;

  .row {
    margin-left: unset;
    margin-right: unset;
  }

  li {
    a, span {
      color: $black;
      text-transform: uppercase;
      font-weight: 500;
      font-size: rem-calc(18);
      line-height: 1;

      @include media("<=large") {
        font-size: rem-calc(16);
      }
      @include media("<medium") {
        font-size: rem-calc(14);
      }
    }

    &:not(:last-child) {
      &:after {
        content: '';
        background-image: url("/assets/templates/images/svg/icon-chevron-right.svg");
        width: 6px;
        height: 11px;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        margin-inline: 8px 9px;
        position: relative;
        left: 3px;

        @include media("<medium") {
          left: 0;
          margin-inline: 8px 4px;
        }
      }
    }
  }
}