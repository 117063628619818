@use "sass:map";

.title-intro-text {
  background: $white;
  border-left: 1px solid $white-grey;
  padding-block: 130px 55px;

  @include media("<medium") {
    padding-block: 45px 20px;
  }

  + .project-images {
    @include media(">large") {
      padding-top: 55px;
      padding-bottom: 129px;
    }

    @include media("<medium") {
      padding-block: 7px 75px;

      .images {
        @include media("<medium") {
          gap: 32px;
        }

        a:first-of-type img {
          height: 231px;
          object-fit: cover;
        }

        a:nth-of-type(2) img {
          height: 325px;
          object-fit: cover;
        }

        a:nth-of-type(3) img {
          height: 231px;
          object-fit: cover;
        }
      }
    }
  }

  .container {
    grid-column: 2/11 span;
    @include pim();
    @include media("<=large") {
      grid-column: 1/12 span;
    }

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .title {
      width: 100%;
      flex: 1 1 100%;

      h2 {
        color: $orange;
        font-family: "astoria", sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        font-size: rem-calc(32);
        line-height: 1.25;
        max-width: 480px;
        margin-bottom: 27px;

        @include media("<medium") {
          font-size: rem-calc(21);
          line-height: 1.52;
          margin-bottom: 19px;
        }
      }
    }

    .intro {
      max-width: 555px;

      p {
        font-size: rem-calc(27);
        font-weight: 500;
        line-height: 1.52;

        @include media("<medium") {
          font-size: rem-calc(18);
          line-height: 1.55;
        }
      }
    }

    .text {
      max-width: 674px;
      @include media("<medium") {
        margin-top: 37px;
      }

      h2 {
        font-weight: 600;
        font-size: rem-calc(20);
        line-height: 1.5;
        color: $grey;
        margin-bottom: 1px;

        @include media("<medium") {
          font-size: rem-calc(18);
          line-height: 1.667;
        }
      }

      p {
        font-weight: 500;
        @include media("<medium") {
          font-size: rem-calc(16);
          line-height: 1.875;
        }


        &:not(:last-child) {
          margin-bottom: 29px;

          @include media("<=large") {
            margin-bottom: 25px;
          }

          @include media("<medium") {
            margin-bottom: 30px;
          }
        }

        a {
          font-weight: 500;
        }
      }
    }
  }


}