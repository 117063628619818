@use "sass:map";

.form-afspraak-maken, .form-offerte-aanvragen {
  padding-block: 51px 112px;

  &:has(+ .contactperson__container) {
    padding-bottom: 0;
  }

  @include media("<=large") {
    padding-block: 40px 80px
  }

  @include media("<medium") {
    padding-block: 30px;
  }


  .form-afspraak-maken-container, .form-offerte-aanvragen-container {
    grid-column: 2/11 span;
    display: flex;
    justify-content: space-between;
    gap: 20px;


    @include pim();

    @include media("<=large") {
      grid-column: 1/12 span;
    }

    @include media("<480px") {
      flex-direction: column;
      justify-content: unset;
    }

    @include media("<medium") {
      flex-direction: column;
    }

    .page-info {
      max-width: 414px;
      margin-top: 146px;

      @include media("<=large") {
        margin-top: 100px
      }

      @include media("<medium") {
        margin-top: 0;
      }

      h1 {
        font-family: "astoria", sans-serif;
        font-size: rem-calc(32);
        line-height: 1.25;
        color: $orange;
        margin-block: 21px;
        //max-width: 400px;
        text-transform: uppercase;
        font-weight: 400;

        @include media("<=large") {
          font-size: rem-calc(28);
        }

        @include media("<medium") {
          font-size: rem-calc(24);
        }
      }

      h2 {
        color: #EBAE09;
        font-family: "astoria", sans-serif;
        font-weight: 400;
        font-size: rem-calc(28);
        line-height: 1.25;
        text-transform: uppercase;
        margin-bottom: 0;

        @include media("<=large") {
          font-size: rem-calc(24);
          margin-bottom: 15px;
        }

        @include media("<medium") {
          font-size: rem-calc(20);
        }
      }

      p {
        font-weight: 500;
        margin-top: 10px;
      }

      .contactperson {
        display: flex;
        gap: 20px;
        margin-top: 56px;
        position: relative;
        margin-bottom: 60px;

        @include media("<=large") {
          gap: 25px;
        }

        @include media("<medium") {
          margin-top: 30px;
          margin-bottom: 40px;
        }

        picture {
          border-radius: 50%;
          overflow: hidden;
          display: block;
          background-color: #DEDDD5;
          flex: 0 0 auto;
          height: 100%;

          @include media("<=large") {
            width: 100px;
            height: 100px;
          }
        }

        .info {
          margin-top: 5px;

          h2 {
            font-weight: 400;
            font-size: rem-calc(18);
            line-height: 1.36;
            color: $black;
            margin-bottom: 0;
            text-transform: capitalize;
            @include media("<medium") {
              font-size: rem-calc(16);
            }
          }

          span {
            font-weight: 400;
            font-size: rem-calc(18);
            line-height: 1.36;
            color: $black;
            //letter-spacing: 0.25px;
            @include media("<medium") {
              font-size: rem-calc(16);
            }
          }

          ul {
            list-style: none;
            margin: 0px;
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            margin-left: 3px;
            gap: 15px;

            li {
              a {
                @include link();
              }

              &:last-child {
                a {
                  font-size: rem-calc(17);
                }
              }
            }
          }
        }
      }
    }
  }

  .form {
    //margin-top: 18px;
    background: $white;
    margin-right: 55px;
    max-width: 844px;
    width: 100%;
    padding: 77px 87px;

    @include media("<=xxlarge") {
      margin-right: 0;
      padding: 60px;
    }


    @include media("<=large") {
      width: 100%;
    }

    @include media("<medium") {
      padding: 30px;
    }

    h2 {
      color: $afspraak-grey;
      font-size: rem-calc(28);
      //max-width: 420px;
      font-weight: 400;
      font-family: "astoria", sans-serif;
      line-height: 1.29;
      text-transform: uppercase;
      margin-bottom: 34px;

      @include media("<medium") {
        font-size: rem-calc(22);
      }
    }

    h3 {
      color: black;
      font-weight: 600;
      font-size: rem-calc(22);
      line-height: 1.25;
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    form {
      display: grid;
      gap: 21px 24px;
      grid-template-columns: repeat(2, fit-content(372px));
      max-width: 675px;
      width: 100%;

      @include media("<=xlarge") {
        grid-template-columns: 1fr;
      }

      @include media("<=large") {
        grid-template-columns: 1fr;
      }

      .form-group {
        margin-top: 0;

        &:first-child {
          grid-column: 1 /2 span;
          @include media("<=xlarge") {
            grid-column: 1 span;
          }
        }


        &.has-value {
          label {
            right: 15px;
          }
        }


        &.textarea {
          grid-column: 1 /2 span;

          @include media("<=xlarge") {
            grid-column: 1 span;
          }

          @include media("<=large") {
            grid-column: 1 span;
          }

        }
      }

      .button-container {
        grid-column: 1 /2 span;
        margin-top: -5px;

        @include media("<=xlarge") {
          grid-column: 1 span;
        }

        @include media("<=large") {
          grid-column: 1 span;
          margin-top: 0;
        }
      }
    }

    &.calendly {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 725px;

      @include media("<=1400px") {
        padding-block: 30px;
      }

      @include media("<medium") {
        min-height: 600px;
      }
    }
  }

  &.one-column {
    .form {
      form {
        display: flex;
        flex-direction: column;

        .form-pagination {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.page-offerte-aanvragen {
  .form-offerte-aanvragen {
    &-container {
      display: block;
      position: relative;

      &:after {
        content: '';
        background-color: #F4F4F0;
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 2px;

        @include media("<medium") {
          left: 0;
          height: 2px;
          width: 100%;
          top: 47%;
        }
      }
    }

    .form, form {
      max-width: unset;
      display: block;
    }

    .form {
      @include media("<medium") {
        padding-bottom: 50px;
      }
    }

    h2 {
      color: #000;
      margin-bottom: 0;
    }

    &__wrapper {
      display: flex;

      @include media("<medium") {
        flex-direction: column;
      }
    }

    &__item {
      width: 50%;

      @include media("<medium") {
        width: 100%;
        padding: 25px 25px 25px 0 !important;
      }

      &:not(:first-of-type) {
        padding: 25px 25px 25px 50px;
      }

      &:first-of-type {
        padding: 25px 50px 25px 0;
      }
    }

    .form-group {
      margin-bottom: 15px;
    }
  }

  .form-pagination {
    position: absolute;
    right: 110px;
    bottom: 50px;

    @include media("<xxlarge") {
      right: 85px;
      bottom: 30px;
    }

    @include media("<medium") {
      right: 55px;
      bottom: 20px;
    }
  }
}