@use "sass:map";

.three-columns-cta {
   padding: 100px 0 ;
   background-color: #fff;
   position: relative;

   @include media("<medium") {
      padding: 50px 0;;
   }

   &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #f4f4f1;
   }

   &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 1px;
      background-color: #f4f4f1;
   }

   @include media("<large") {
      padding: 50px 25px;
   }

   &__wrapper {
      grid-column: 2/10 span;

      @include media("<medium") {
         grid-column: 1/12 span;
      }

      h2 {
         font-size: 60px;
         color: #BAB9AC;
         text-transform: uppercase;
         font-weight: 500;
         margin-bottom: 50px;

         @include media("<medium") {
            font-size: 32px;
            margin-bottom: 25px;
         }
      }
   }

   &__items {
      display: flex;
      gap: 15px;

      @include media("<medium") {
         flex-direction: column;
      }
   }

   &__item {
      width: 33%;
      background-color: #F4F4F1;

      @include media("<medium") {
         width: 100%;
      }

      img {
         width: 100%;
         max-height: 321px;
      }
   }

   &__item-content {
      padding: 50px;

      @include media("<large") {
         padding: 25px;
      }

      h3 {
         text-transform: uppercase;
         font-weight: 500;
         font-size: 23px;
      }
   }
   
}