@use "sass:map";

.centered-text {
    background: $white;
    padding-block: 119px 65px;
    border-left: 1px solid $white-grey;

    @include media("<=large") {
        padding-block: 60px 30px;
    }

    @include media("<medium") {
        padding-block: 30px;
    }


    .centered-text-container {
        grid-column: 4 / 7 span;
        //display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include media("<=xxlarge") {
            grid-column: 3/9 span;
        }



        @include media("<=large") {
            grid-column: 1/12 span;
            padding-inline: $mobile-padding-inline;
        }

        h2 {
            font-weight: 600;
            font-size: rem-calc(20);
            line-height: 1.5;
            color: $grey;
            margin-bottom: 1px;
        }

        p {
            max-width: 943px;
            font-weight: 500;

            &:not(:last-child) {
                margin-bottom: 29px;

                @include media("<=large") {
                    margin-bottom: 25px;
                }

                @include media("<medium") {
                    margin-bottom: 1rem;
                }
            }

            a {
                font-weight: 500;
            }
        }

        ul,ol {
            li {
                font-size: rem-calc(18);
                font-weight: 500;
            }
        }
    }

    .text {
        .btn {
            display: inline-block;
            @include media(">=large") {
                margin-top: 99px;
            }

            @include media("<=large") {
                margin-top: 40px;
            }

            @include media("<medium") {
                margin-top: 25px;
            }
        }
    }
}
