.page-maatwerk {
  background-color: $white;

  .site-container {
    background-color: $white-grey;
  }

  section:first-child {
    position: relative;
    top: 35px;
  }

  .configurator-layout {
    .container {
      @include media(">large") {
        grid-template-rows: minmax(10px, 647px) minmax(10px, 1fr);
      }

      @include media("<=large") {
        grid-template-rows: minmax(1px, 570px) auto;
      }

      .sidebar h1 {
        margin-bottom: 14px;
      }

      .sidebar {
        &:last-of-type {
          border-top: 1px solid rgba($black, 0.07);
          //border-bottom: 1px solid #F4F4F4;
          padding: 29px 20px 20px 36px;
          margin-top: 39px;

          @include media("<=large") {
            padding: 29px 20px 20px;
          }

          .information {
            margin-top: 0;
          }

          h2 {
            text-transform: uppercase;
            font-weight: 600;
            font-size: rem-calc(21);
            line-height: 1.28;
            letter-spacing: 0.42px;
            margin-left: 4px;

            @include media("<medium") {
              padding-left: 20px;
              margin-left: 0;
            }
          }

          table {
            margin-top: 40px;

            @include media("<medium") {
              padding: 11px 20px 26px 18px;
            }

            tbody {
              display: flex;
              flex-direction: column;
              gap: 5px;

              @include media("<medium") {
                gap: 7px;
              }
            }

            tr {
              //margin-bottom: 15px;

              &.kleur, &.afmeting {
                min-height: 48px;
              }

              &.empty {
                td {
                  &:last-of-type {
                    opacity: 0.30;
                  }
                }
              }

              td {
                font-size: rem-calc(18);
                font-weight: 500;
                line-height: 1.27;
                color: $black;
                min-width: 224px;

                @include media("<=large") {
                  min-width: 173px;
                }

                @include media("<medium") {
                  font-size: rem-calc(16);
                  min-width: 173px;
                }

                &:nth-of-type(2) {
                  font-weight: 600;
                }

              }
            }
          }

          .price {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid rgba($black, 0.07);
            border-bottom: 1px solid rgba($black, 0.07);
            padding-block: 20px 21px;
            padding-inline: 4px 39px;
            margin-top: 29px;
            align-items: center;

            @include media("<medium") {
              background-color: #F9F9F9;
              padding: 16px 20px 13px 20px;
              margin-top: 0;
            }

            span {
              &:first-of-type {
                font-weight: 600;
                font-size: rem-calc(21);
                line-height: 1.28;
                letter-spacing: 0.42px;
                color: $black;
                text-transform: uppercase;

                @include media("<medium") {
                  font-size: rem-calc(16);
                  line-height: 1.25;
                  letter-spacing: 0.32px;
                }
              }

              &:last-of-type {
                font-weight: 500;
                color: $black;
                font-size: rem-calc(28);
                line-height: 1.21;
                letter-spacing: 0.56px;
                font-family: "astoria", sans-serif;

                @include media("<medium") {
                  font-size: rem-calc(18);
                  line-height: 1.66;
                  letter-spacing: 0.36px;
                }
              }
            }
          }

          .note {
            padding: 35px 0 0 4px;

            @include media("<medium") {
              display: none;
            }

            p {
              font-size: rem-calc(18);
              line-height: 1.27;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .project-images + .sidebar-form {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 27px;
      width: 100%;
      top: 0;
      left: 0;
      background-image: url("/assets/templates/images/svg/lijntje-parallax-banner.svg");
      background-size: cover;
      background-repeat: no-repeat;

      @include media("<medium") {
        //top: 20px;
        left: 18px;
        width: calc(100% - 18px);

      }
    }
  }

  .sidebar-form {
    @include media(">large") {
      padding-block: 129px 150px;
      .grid {
        &::before {
          top: -40px
        }
      }
    }

    .sidebar-content {
      margin-top: 4px;

      .contactperson {
        left: 0;
        margin-top: 44px;
      }
    }

    @include media("<medium") {
      padding-block: 82px 121px;


      .sidebar-content {

        h2 {
          font-size: rem-calc(21);
          line-height: 1.52;
          margin-bottom: 18px;
        }

        .contactperson {
          margin-top: 30px;
        }
      }

      .form {
        display: none;
      }
    }
  }
}
