@use "sass:map";

.project-overview {
  padding-block: 24px 30px;

  .projects-container {
    grid-column: 2 / 11 span;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px 50px;


    @include media("<=large") {
      grid-column: 1 / 12 span;
      flex-direction: column;
      padding-inline: 16px;
      gap: 40px;
    }

    @include media("<medium") {
      gap: 25px;
      grid-template-columns: 1fr;
    }

    .project-overview-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 726px;


      picture {
        overflow: hidden;

        @include media("<medium") {
          height: 258px;
        }

        img {
          transform: scale(1);
          transition: all ease-in-out 0.3s;
          height: 100%;
        }
      }

      &:focus, &:hover {
        img {
          transform: scale(1.1);
        }

        .project-link {
          &:before {
            transform: translateY(-50%) rotate(-90deg);
            background-color: $orange;
          }
        }

        @include media("<medium") {
          .project-info::before {
            transform: translateY(-50%) rotate(-90deg);
            background-color: $orange;
          }
        }
      }

      .project-info {
        @include media("<medium") {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          position: relative;
          padding-left: 49px;
          margin-top: 30px;

          &:before {
            content: '';
            mask-image: url('/assets/templates/images/svg/arrow-tabs.svg');
            background-color: $black;
            background-size: contain;
            background-repeat: no-repeat;
            width: 21px;
            height: 21px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            position: absolute;

            transition: all ease-in-out 0.3s;
          }

          h3 {
            font-family: 'bilo', sans-serif;
            font-weight: 600;
            font-size: rem-calc(18);
            line-height: 1.27;
            letter-spacing: 0.36px;
            margin: 0;
            padding-right: 8px;
          }

          .location-type {
            margin-top: 0;
            display: inline-block;

            span {
              font-weight: 400;
              font-size: rem-calc(18);
              line-height: 1.27;
              letter-spacing: 0.36px;
              text-transform: unset;
            }
          }
        }
      }

      .location-type {
        margin-top: 30px;
        display: flex;
        gap: 14px;

        @include media("<medium") {
          margin-top: 0;
        }

        span {
          font-size: rem-calc(16);
          font-weight: 400;
          line-height: 1.25;
          letter-spacing: 0.32px;
          display: block;
          color: $black;
          text-transform: uppercase;

          &:first-child {
            &:after {
              content: '|';
              position: relative;
              right: -7px;

              @include media("<medium") {
                display: none;
              }
            }
          }

          &:last-of-type {
            @include media("<medium") {

              display: none;
            }
          }
        }
      }

      h3 {
        color: $black;
        font-family: "astoria", sans-serif;
        font-size: rem-calc(32);
        font-weight: 500;
        line-height: 1.18;
        letter-spacing: 0.64px;
        margin-top: 5px;
        margin-bottom: 30px;
      }

      .project-link {
        font-size: rem-calc(18);
        font-weight: 400;
        line-height: 1.27;
        letter-spacing: 0.36px;
        color: $black;
        text-transform: uppercase;
        padding-left: 32px;
        position: relative;
        display: block;
        @include media("<medium") {
          display: none;
        }


        &:before {
          content: '';
          mask-image: url('/assets/templates/images/svg/arrow-project.svg');
          background-color: $black;
          background-size: contain;
          background-repeat: no-repeat;
          width: 17px;
          height: 17px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          position: absolute;

          transition: all ease-in-out 0.3s;
        }
      }

    }
  }

  .featured-project {
    flex: 1 1 100%;
    display: flex;
    gap: 50px;
    grid-column: 1 / 2 span;

    @include media("<=large") {
      gap: 40px;
    }

    @include media("<medium") {
      grid-column: 1 / 1 span;
      flex-direction: column;
    }

    .project-overview-item {
      position: relative;
      max-width: unset;

      picture {
        height: 100%;

        img {
          object-fit: cover;
        }
      }

      .project-info {
        @include media(">=medium") {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: $white-grey;
          padding: 10px 48px 0px 0px;
        }
      }
    }

    .info {
      background-color: $letter-brown;
      display: flex;
      flex-direction: column;
      padding: 57px 75px 48px 50px;
      max-width: 337px;

      span {
        color: $red;
        font-size: rem-calc(24);
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.48px;

        @include media("<medium") {
          font-size: rem-calc(18);
          letter-spacing: 0.36px;
        }
      }

      h2 {
        font-size: rem-calc(38);
        line-height: 1.1;
        color: $white;
        font-weight: 500;
        letter-spacing: 0.76px;
        margin-top: 3px;
        margin-bottom: 57px;

        @include media("<medium") {
          font-size: rem-calc(32);
          line-height: 1.06;
          letter-spacing: 0.64px;
          margin-top: 11px;
          margin-bottom: 38px;
        }
      }

      .link-icon {
        color: $white;
        display: inline-block;
        margin-top: 57px;
        font-size: rem-calc(18);
        font-weight: 600;

        @include media("<medium") {
          margin-top: 52px;
        }

        &:before {
          background-image: url("/assets/templates/images/svg/btn-icon-red.svg");
        }
      }
    }
  }

  .pagination {
    grid-column: 2/11 span;
    padding-block: 133px 119px;

    @include media("<=large") {
      grid-column: 1/ 12 span;
      padding-inline: $mobile-padding-inline;
      padding-block: 50px 70px;
    }

    @include media("<medium") {
      padding-block: 30px 50px;
    }

    ul {
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: center;
      gap: 40px;
      flex-wrap: wrap;

      @include media("<=large") {
        gap: 20px;
      }

      li {
        a {
          font-size: rem-calc(24);
          line-height: 1;
          font-weight: 500;
          text-transform: uppercase;
          padding-bottom: 12px;
          border-bottom: 1px solid transparent;
          position: relative;
          width: 35px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          letter-spacing: 0.48px;
          left: 18px;

          @include media("<=large") {
            position: static;
          }

          &.is-active {
            font-weight: 600;
            border-bottom: 1px solid $orange;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 1px;
            background-color: $black;
            transition: width ease-in-out 0.3s;
          }

          &:hover, &:focus {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}