@use "sass:map";

.brochure-form {
  padding-block: 186px 180px;

  @include media("<=large") {
    padding-block: 100px 90px
  }

  @include media("<medium") {
    padding-block: 30px;
  }


  .brochure-form-container {
    grid-column: 2/10 span;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include media("<=global-width") {
      grid-column: 2/11 span;
    }

    @include pim();

    @include media("<=large") {
      grid-column: 1/12 span;
    }

    @include media("<480px") {
      flex-direction: column;
      justify-content: unset;
    }

    .page-info {
      position: relative;
      left: 44px;
      flex: 1 0 auto;

      @include media("<=xxlarge") {
        left: 0;
      }

      @include media("<=xlarge") {
        flex: 1 1 auto;
      }

      @include media("<=large") {
        position: static;
      }


      h1 {
        color: $white;
        font-size: rem-calc(65);
        max-width: 420px;
        font-weight: 400;
        font-family: "astoria", sans-serif;
        line-height: 1;
        text-transform: uppercase;
        text-align: center;


        @include media("<=xlarge") {
          font-size: rem-calc(45);
        }

        @include media("<=large") {
          font-size: rem-calc(35);
        }

        @include media("<medium") {
          font-size: rem-calc(30);
        }

      }

      img {
        position: relative;
        top: -57px;
        left: 16px;


        @include media("<=xlarge") {
          top: -53px;
          left: 0;
        }

        @include media("<=large") {
          top: -45px;
          left: -15px;
        }

        @include media("<480px") {
          max-width: 50%;
          left: 50%;
          transform: translateX(-75%);
          top: -5px;
        }
      }
    }
  }

  .form {
    margin-top: 18px;
    @include media("<=large") {
      width: 100%;
    }

    h2 {
      color: $white;
      font-size: rem-calc(28);
      //max-width: 420px;
      font-weight: 400;
      font-family: "astoria", sans-serif;
      line-height: 1.29;
      text-transform: uppercase;
      margin-bottom: 29px;

      @include media("<medium") {
        font-size: rem-calc(22);
      }

    }

    h3 {
      color: $white;
      font-size: rem-calc(18);
      line-height: 1.667;
      font-weight: 600;
      margin-bottom: 15px;
    }

    form {
      display: grid;
      gap: 21px;
      grid-template-columns: repeat(2, fit-content(372px));
      max-width: 675px;
      width: 100%;

      @include media("<=large") {
        grid-template-columns: 1fr;
      }

      .form-group {
        margin-top: 0;

        label {
          color: $white;
        }


        input, textarea {
          color: $white;
        }

        &.has-value {
          label {
            right: -2px;
          }
        }


        &.checkboxes {
          grid-column: 1 /2 span;
          margin-top: 26px;
          @include media("<=large") {
            grid-column: 1 span;
          }

        }
      }

      .button-container {
        grid-column: 1 /2 span;
        margin-top: 3px;
        @include media("<=large") {
          grid-column: 1 span;
        }
      }
    }
  }

}