/**
* @param {Number} $value
* @param {Number} $base-value
 */
@function rem-calc($value, $base-value: 16) {
  $value: $value / $base-value * 1rem;
  @if ($value == 0rem) {
    $value: 0;
  }
  // Turn 0rem into 0
  @return $value;
}

@function em-calc($value, $base-value: 16) {
  $value: $value / $base-value * 1em;
  @if ($value == 0em) {
    $value: 0;
  }
  // Turn 0em into 0
  @return $value;
}

@mixin parallax-bg() {
  background-attachment: fixed;
  background-size: cover;

  @supports (-webkit-touch-callout: none) {
    background-attachment: unset;
  }
}

@mixin grid() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  max-width: 1640px;

  @include media(">=large") {
    margin-right: 16px;
  }

  @include media(">1920px") {
    margin: 0 auto 0 auto;
    position: relative;
    left: -122px;
  }
}

@mixin full-grid {
  @include media(">1920px") {
    grid-column: 1/12 span;
  }
}

@mixin pim() {
  @include media("<=large") {
    padding-inline: $mobile-padding-inline;
  }
}