@use "sass:map";

.homepage-paragraph-content {
  background: $white;
  position: relative;
  padding-block: 64px 0;
  border-left: 1px solid $white-grey;

  @include media("<medium") {
    padding-block: 5px 56px;
  }

  .right-side {
    grid-column: 8 / 4 span;

    @include media("<=large") {
      grid-column: 1/12 span;
      padding-inline: 16px;
    }
  }

  .highlight-text {
    grid-column: 3 / 4 span;
    display: flex;
    gap: 51px;
    //margin-bottom: 31px;
    position: relative;
    left: 50px;

    @include media("<=large") {
      grid-column: 1/12 span;
      left: 0;
      padding-inline: 16px;
      gap: 29px;
    }

    .hightlight-character {
      //border-right: 2px solid $letter-brown;
      border-right: 2px solid $white-grey;
      overflow: hidden;
      flex: 0 0 auto;
      //max-height: 149px;
      position: relative;
      //left: -5px;

      @include media("<medium") {
        border-right-color: $letter-grey;
        max-height: 80px;

      }

      .character {
        color: $letter-brown;
        font-size: rem-calc(136);
        font-weight: 600;
        line-height: 1;
        width: 70px;
        position: relative;
        top: -10px;
        left: 2px;

        @include media("<medium") {
          color: $letter-grey;
          font-size: rem-calc(70);
          position: static;
          width: 37px;
        }
      }
    }

    p {
      font-size: rem-calc(21);
      font-weight: 600;
      line-height: 1.57;
      margin-bottom: 0;
      //letter-spacing: 0.38px;
      color: $black;
      max-width: 350px;
      align-self: baseline;
      display: block;
      position: relative;

      &::before {
        content: '';
        width: 2px;
        height: 100%;
        background-color: $letter-brown;
        position: absolute;
        top: 0;
        left: -53px;

        @include media("<=large") {
          left: -31px;
        }

        @include media("<medium") {
          background-color: $letter-grey;
        }
      }

      @include media("<medium") {
        font-size: rem-calc(18);
      }
    }
  }

  p {
    font-size: rem-calc(18);
    font-weight: 500;
    line-height: 1.667;
    color: $black;
    //letter-spacing: 0.25px;
    margin-bottom: 29px;

    @include media("<medium") {
      font-size: rem-calc(16);
      line-height: 1.625;
      margin-bottom: 24px;

      &:first-child {
        margin-top: 27px;
      }

    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    font-weight: 600;
    font-size: rem-calc(20);
    line-height: 1.5;
    color: $grey;
    margin-bottom: 0;
  }
}