@use "sass:map";

section.cta-magazine {
  background: $dark-grey;

  @include media(">1024px","<1250px") {
    .row {
      .large-7  {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }

  .cta-magazine-container {
    display: flex;
    position: relative;
    top: 0;
    transition: all ease-in-out 0.3s;
    justify-content: flex-end;

    @include media(">1310px","<1800px") {
      justify-content: unset;
    }

    @include media("<=large") {
      flex-direction: column;
      justify-content: unset;
      align-items: center;
    }

    &:hover, &:focus {
      top: -10px;
    }

    picture {
      position: absolute;
      top: -41px;
      left: -29px;

      @include media("<=large") {
        position: relative;
        width: 214px;
        top: -47px;
        left: -16px;
        img {
          width: 214px;
        }
      }
    }

    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 14px;
      padding-block: 46px 68px;
      padding-inline: 0 223px;

      @include media(">1310px","<1800px") {
        padding-inline: 250px 0;
      }

      @include media(">large", "<=1310px") {
        padding-inline: 235px 0;
      }

      @include media("<=large") {
        position: relative;
        top: -13px;
        padding-block: 0 50px;
        padding-inline: 0;
      }
    }

    h2 {
      font-size: rem-calc(45);
      font-weight: 400;
      letter-spacing: 0.85px;
      color: $white;
      margin-bottom: 1px;

      @include media("<=large") {
        font-size: rem-calc(32);
        letter-spacing: 0.64px;
      }

      strong {
        font-weight: 700;
      }
    }

    .link {
      font-weight: 500;
      font-size: rem-calc(18);
      letter-spacing: 0.65px;
      color: $white;
      text-transform: uppercase;
      line-height: 1.2777;
      position: relative;
      padding-left: 30px;

      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        background-image: url("/assets/templates/images/svg/icon-menu.svg");
        width: 12px;
        height: 11px;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}