@use "sass:map";

.header__popup {
    background: rgba(235,174,9,1); 
    text-align: center; 
    margin-left: -150px; 
    position: relative;
    min-height: 5px;
    display: block;

    @include media("<=large") {
        display: none;
    }

    &:has(> span) {
        padding: 10px 0; 
    }

    span, span p, span p a {
        color: #fff;
        font-weight: 600; 
    }

    p {
        margin-bottom: 0;
    }

    .header__popup-close {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%, 30%);
        z-index: 999;
        cursor: pointer;
        height: 25px;
        width: 25px;
        display: block;
    }
}