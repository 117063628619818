.page-blogs-detail {
    background-color: $white;

    .site-container {
        background-color: $white-grey;
    }

    .header-new-menu {
        background-color: $white;
    }

    .header__logo {
        .dark {
            display: block;
        }

        .light {
            display: none;
        }
    }
    
    .header__nav ul li a, .header__nav .header-review-button .google-review strong {
        color: #0a0a0a;
    }

    .header__nav .header-review-button .google-review strong i {
        color: rgb(112,112,112,.5)
    }
    
    .header__wrapper {
        padding-bottom: 10px;
    }

    .pagetitle {
        padding-top: 90px;
    }
}