@use "sass:map";

.page-banner {
  padding-block: 10px 73px; //45px 73px

  .parallax-banner {
    grid-column: 2/11 span;
    height: 654px;
    position: relative;
    z-index: 2;
    @include parallax-bg();

    @include media("<=large") {
      grid-column: 1/ 12 span;
      height: 400px;
    }

    @include media("<medium") {
      height: 300px;
    }
  }
}
