@use "sass:map";

.sidebar-form {
  border-left: 1px solid $white-grey;
  background: $white;
  padding-block: 49px 120px;

  @include media("<medium") {
    padding-block: 82px 101px;
  }

  .grid {
    position: relative;

    @include media("<=large") {
      padding-inline: $mobile-padding-inline;
    }

    &:before {
      content: '';
      width: 8px;
      height: 100%;
      position: absolute;
      top: 13px;
      left: 50%;
      transform: translateX(-200%);
      background-image: url('/assets/templates/images/svg/sidebar-form-line.svg');
      background-size: contain;
      background-repeat: no-repeat;

      @include media("<=xxlarge") {
        transform: translateX(0);
        left: 55%;
      }

      @include media("<=large") {
        display: none;
      }
    }
  }

  .sidebar-content {
    grid-column: 2 /4 span;

    @include media("<=xxlarge") {
      grid-column: 2 /5 span;
    }

    @include media("<=large") {
      grid-column: 1 / 6 span;
    }

    @include media("<medium") {
      grid-column: 1/ 12 span;
    }

    h2 {
      color: $orange;
      font-family: "astoria", sans-serif;
      text-transform: uppercase;
      font-weight: 400;
      font-size: rem-calc(32);
      line-height: 1.25;
      margin-bottom: 30px;

      @include media("<=large") {
        font-size: rem-calc(22);
      }

      @include media("<medium") {
        margin-bottom: 25px;
      }
    }

    p {
      font-weight: 500;

      a {
        font-weight: 500;
      }
    }

    .contactperson {
      //margin-bottom: 146px;
      //position: relative;
      //left: 138px;
      display: flex;
      gap: 52px;
      margin-top: 63px;
      position: relative;
      left: -5px;
      margin-bottom: 77px;

      @include media("<=large") {
        gap: 25px;
      }

      @include media("<medium") {
        margin-top: 30px;
        margin-bottom: 40px;
      }

      picture {
        border-radius: 50%;
        width: 177px;
        height: 177px;
        overflow: hidden;
        display: block;
        background-color: #DEDDD5;
        //margin-bottom: 24px;
        flex: 0 0 auto;

        @include media("<=large") {
          width: 100px;
          height: 100px;
          //margin-bottom: 0;
        }
      }

      .info {
        margin-top: 5px;

        h3 {
          font-weight: 600;
          font-size: rem-calc(22);
          line-height: 1.36;
          color: $black;
          margin-bottom: 0;
          @include media("<medium") {
            font-size: rem-calc(18);
            margin-bottom: 3px;
          }
        }

        span {
          font-weight: 400;
          font-size: rem-calc(22);
          line-height: 1.36;
          color: $black;
          //letter-spacing: 0.25px;
          @include media("<medium") {
            font-size: rem-calc(18);
          }
        }

        ul {
          list-style: none;
          margin: 0px;
          display: flex;
          flex-direction: column;
          margin-top: 28px;
          margin-left: 3px;
          gap: 15px;

          @include media("<medium") {
            margin-top: 10px;
            margin-left: 0;
            gap: 7px
          }
          li {
            a {
              @include link();
            }

            &:last-child {
              a {
                font-size: rem-calc(17);
              }
            }
          }
        }
      }
    }
  }

  .form {
    grid-column: 8 / 5 span;

    @include media("<=large") {
      grid-column: 7 / 6 span;
    }

    @include media("<medium") {
      grid-column: 1/ 12 span;
      margin-top: 30px;
    }

    h2 {
      font-size: rem-calc(32);
      color: $brown;
      font-family: "astoria", sans-serif;
      font-weight: 400;
      line-height: 1.25;
      text-transform: uppercase;
      margin-bottom: 35px;

      @include media("<=large") {
        font-size: rem-calc(22);
      }
    }

    button {
      margin-top: 29px;
    }
  }

}