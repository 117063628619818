@use "sass:map";

.related-projects {
  padding-block: 151px 171px;
  background: $white-grey;
  @include media("<medium") {
    padding-block: 60px 148px;
  }

  .text-info {
    grid-column: 2 / 11 span;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 47px;

    @include media("<medium") {
      grid-column: 1/12 span;
      padding-inline: 16px;
      margin-bottom: 48px;
      justify-content: unset;
      flex-direction: column;
      gap: 20px;
    }

    h2 {
      color: $title-grey;
      font-family: "astoria", sans-serif;
      line-height: 1;
      font-size: rem-calc(32);
      font-weight: 400;
      text-transform: uppercase;

      @include media("<medium") {
        font-size: rem-calc(32);
        max-width: 300px;
      }
    }

    .cta-text {
      .icon-link {
        margin-top: 9px;
        @include link-icon();
        letter-spacing: 0.35px;

        @include media("<medium") {
          font-size: rem-calc(16);
          margin-top: 19px;
        }
      }
    }
  }

  .projects-container {
    grid-column: 2 / 11 span;
    display: flex;
    gap: 50px;

    @include media("<medium") {
      grid-column: 1 / 12 span;
      flex-direction: column;
      padding-inline: 16px;
    }

    .project-overview-item {
      display: flex;
      flex-direction: column;

      picture {
        overflow: hidden;

        @include media("<medium") {
          height: 258px;
        }

        img {
          transform: scale(1);
          transition: all ease-in-out 0.3s;
          height: 100%;
        }
      }

      &:focus, &:hover {
        img {
          transform: scale(1.1);
        }

        .project-link {
          &:before {
            transform: translateY(-50%) rotate(-90deg);
            background-color: $orange;
          }
        }

        @include media("<medium") {
          .project-info::before {
            transform: translateY(-50%) rotate(-90deg);
            background-color: $orange;
          }
        }
      }

      .project-info {
        @include media("<medium") {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          position: relative;
          padding-left: 49px;
          margin-top: 30px;

          &:before {
            content: '';
            mask-image: url('/assets/templates/images/svg/arrow-tabs.svg');
            background-color: $black;
            background-size: contain;
            background-repeat: no-repeat;
            width: 21px;
            height: 21px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            position: absolute;

            transition: all ease-in-out 0.3s;
          }

          h3 {
            font-family: 'bilo', sans-serif;
            font-weight: 600;
            font-size: rem-calc(18);
            line-height: 1.27;
            letter-spacing: 0.36px;
            margin: 0;
            padding-right: 8px;
          }

          .location-type {
            margin-top: 0;
            display: inline-block;

            span {
              font-weight: 400;
              font-size: rem-calc(18);
              line-height: 1.27;
              letter-spacing: 0.36px;
              text-transform: unset;
            }
          }
        }
      }

      .location-type {
        margin-top: 30px;
        display: flex;
        gap: 14px;

        @include media("<medium") {
          margin-top: 0;
        }

        span {
          font-size: rem-calc(16);
          font-weight: 400;
          line-height: 1.25;
          letter-spacing: 0.32px;
          display: block;
          color: $black;
          text-transform: uppercase;

          &:first-child {
            &:after {
              content: '|';
              position: relative;
              right: -7px;

              @include media("<medium") {
                display: none;
              }
            }
          }

          &:last-of-type {
            @include media("<medium") {

              display: none;
            }
          }
        }
      }

      h3 {
        color: $black;
        font-family: "astoria", sans-serif;
        font-size: rem-calc(32);
        font-weight: 500;
        line-height: 1.18;
        letter-spacing: 0.64px;
        margin-top: 5px;
        margin-bottom: 28px;
      }

      .project-link {
        font-size: rem-calc(18);
        font-weight: 400;
        line-height: 1.27;
        letter-spacing: 0.36px;
        color: $black;
        text-transform: uppercase;
        padding-left: 32px;
        position: relative;
        display: block;
        @include media("<medium") {
          display: none;
        }


        &:before {
          content: '';
          mask-image: url('/assets/templates/images/svg/arrow-project.svg');
          background-color: $black;
          background-size: contain;
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          position: absolute;

          transition: all ease-in-out 0.3s;
        }
      }

    }
  }
}