@use "sass:map";

.header-new-menu {
    width: 100%;

    @include media("<=large") {
        display: none;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        margin-right: 15px;
        gap: 60px;

        @include media("<=large") {
            padding-inline: 17px;
            padding-block: 28px;
        }

        @include media(">1920px") {
            margin-right: 155px;
        }
    }

    &__left {
        ul {
            display: flex;
            gap: 35px;
            padding: 15px 0;
            flex-wrap: nowrap;
            position: relative;
            align-items: start;
            left: 2px;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin: 0;
                position: relative;
                //transition: all .5s ease-in-out;

                &:hover::after {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 3px;
                    background-color: #ebae09;
                    bottom: -14px;
                    left: 0;
                }

                &.has-children {
                    position: relative;
                    padding-right: 5px;

                    &:after {
                        content: "";
                        width: 12px;
                        height: 7px;
                        background-image: url(/assets/templates/images/svg/chevron-down.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        position: absolute;
                        top: 9px;
                        right: -15px;
                        transition: all .3s ease-in-out;
                        filter: brightness(0%);
                    }

                    &:hover {
                        &:after {
                            background-color: transparent;
                            bottom: unset;
                            left: unset;
                            transform: rotateX(180deg);
                        }
                    }
                }

                ul {
                    display: none;
                    z-index: 999;
                }

                &:hover,
                &:focus,
                &.hover {
                    &:before {
                        left: -17px;
                        opacity: 1;
                    }

                    &.has-children {
                        ul {
                            background: $white;
                            display: flex;
                            flex-direction: column;
                            gap: 15px 50px;
                            flex-wrap: wrap;
                            margin: 0;
                            position: absolute;
                            top: 20px;
                            left: -25px;
                            padding: 25px 15px 15px 25px;
                            min-width: 205px;

                            li {
                                position: relative;

                                &:hover,
                                &:focus {
                                    &:before {
                                        left: -22px;
                                        opacity: 1;
                                    }

                                    &:after {
                                        bottom: 0;
                                    }
                                }

                                a {
                                    color: $black;
                                    font-size: rem-calc(18);
                                    line-height: 1.277;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__right {
        position: relative;

        a {
            position: relative;

            &::before {
                background-image: url('/assets/templates/images/svg/Icon-telephone-orange.svg');
                background-repeat: no-repeat;
                position: absolute;
                content: '';
                bottom: 0;
                left: -20px;
                height: 15px;
                width: 15px;

            }
        }

        &::before {
            position: absolute;
            top: 0;
            left: -40px;
            content: '';
            width: 1px;
            height: 100%;
            background-color: rgb(222, 221, 213);
        }
    }
}

.header.mobile-bg:has(+ .sticky-header-wrapper.show) .header-new-menu, .header.mobile-bg:has(+ .sticky-header-wrapper.show) .header__popup {
    display: none;
}