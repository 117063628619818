$fa-font-path: '../fonts';

$root: (
        border-radius: 10px,
        font-family: 'Bilo, sans-serif',
        heading-font-family: 'Bilo, sans-serif',
        font-size: rem-calc(16),
        box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4),
);

/**
Headings
 */
$heading-color: #000;
$h1-font-size: rem-calc(62);
$h2-font-size: rem-calc(40);
$h3-font-size: rem-calc(34);
$h4-font-size: rem-calc(26);
$h5-font-size: rem-calc(22);
$h6-font-size: rem-calc(18);

/**
Suptitle
 */
$suptitle-font-size: rem-calc(17);
$suptitle-font-weight: 700;
$suptitle-color: #50AB41;
$suptitle-line-width: 60px;

/**
Colors
 */
$black: #1E1E1C;
$white: #fff;
$orange: #EBAE09;
$dark-orange: #D19B08;
$letter-brown: #860304;
$letter-grey: #DEDDD4;
$title-grey: #BAB9AC;
$grey: #949283;
$afspraak-grey: #BAB9AA;
$light-grey: #00000d;
$dark-grey: #121211;
$white-grey: #F4F4F1;
$brown: #7B190F;
$footer-title: #575756;
$social-icon-bg: #2D2E30;
$social-icon-color: #595B60;
$footer-border: #2E2E2B;
$form-border-color: #BAB9AB;
$btn-alt-bg: #DCDBD2;
$red: #860304;

$mobile-padding-inline: 16px;