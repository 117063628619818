@use "sass:map";

.faq {
  padding-block: 130px 105px;
  background: $white-grey;

  @include media("<=large") {
    padding-block: 60px 100px;
  }

  @include media("<medium") {
    padding-block: 30px 60px;
  }

  .faq-container {
    grid-column: 4/ 7 span;
    display: grid;
    grid-template-columns: repeat(2, minmax(10px, 460px));
    flex-direction: column;
    align-items: baseline;
    //row-gap: 20px;
    column-gap: 30px;
    //flex-wrap: wrap;
    max-width: 960px;

    @include media("<=1800px") {
      grid-column: 3/9 span;
    }

    @include media("<=xxlarge") {
      grid-column: 2/12 span;
    }

    @include media("<=large") {
      grid-column: 1/12 span;
    }

    @include pim();

    @include media("<medium") {
      grid-template-columns: 1fr;
    }

    h2 {
      color: $title-grey;
      font-family: "astoria", sans-serif;
      line-height: 1;
      font-size: rem-calc(60);
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 74px;
      //flex: 1 1 100%;
      grid-column: 1/2 span;
      text-align: center;

      @include media("<=large") {
        font-size: rem-calc(40);
        margin-bottom: 40px;
      }

      @include media("<medium") {
        font-size: rem-calc(32);
        margin-bottom: 25px;
        grid-column: 1;
      }
    }

    ul {
      margin: 0;
      list-style: none;
      display: flex;
      row-gap: 20px;
      //column-gap: 30px;
      background: transparent;
      justify-content: space-between;
      flex-direction: column;
      //flex-wrap: wrap;
      width: 100%;
      align-items: baseline;
      max-width: 460px;
      //flex: 1 1 calc(50% - 15px);

      @include media("<medium") {
        grid-template-columns: 1fr;
      }

      @include media("<=large") {
        max-width: unset;
        margin-top: 20px;
      }

      .accordion-item {
        border: unset;
        background: $white;
        margin: 0;
        max-width: 460px;
        width: 100%;

        @include media("<=xlarge") {
          max-width: 400px;
        }

        @include media("<=large") {
          max-width: unset;
        }

        &.is-active {
          .accordion-title {
            border-bottom: 1px solid $white-grey;

            &::before {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }

        .accordion-title {
          margin: 0;
          border: 0;
          background: unset;
          font-size: rem-calc(18);
          color: $black;
          line-height: 1.667;
          font-weight: 500;
          padding: 11px 20px;
          position: relative;
          border-bottom: 1px solid transparent;
          padding-right: 35px;

          &::before {
            content: '';
            background-image: url('/assets/templates/images/svg/chevron-down-black.svg');
            //background-color: $white;
            background-size: contain;
            background-repeat: no-repeat;
            width: 16px;
            height: 8px;
            right: 17px;
            top: 55%;
            transform: translateY(-50%);
            margin: 0;
            transition: all ease-in-out 0.3s;
          }

          &:hover, &:focus {
            background: unset;
          }
        }

        .accordion-content {
          background: unset;
          border: unset;
          padding: 16px 20px 23px;

          @include media("<medium") {
            padding-top: 7px;
          }

          p {
            font-size: rem-calc(16);
            font-weight: 500;
            line-height: 1.625;
            color: $black;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}