@use "sass:map";

.project-images {
    border-left: 1px solid $white-grey;
    background: $white;
    padding-block: 49px 98px;

    .images {
        grid-column: 2/11 span;
        display: flex;
        flex-wrap: wrap;
        gap: 55px;

        @include media("<=large") {
            grid-column: 1 / 12 span;
            padding-inline: $mobile-padding-inline;
            gap: 30px;
        }

        a {
            flex: 0 1 auto;
            position: relative;

            &:nth-child(1) {
                width: 59.5%;
            }

            &:nth-child(2) {
                width: calc(40.5% - 55px);

                @include media('<large') {
                    width: calc(40.5% - 30px);
                }
            }

            &:nth-child(4) {
                width: 37%;
            }

            &:nth-child(5) {
                width: calc(63% - 55px);

                @include media('<large') {
                    width: calc(63% - 30px);
                }
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

            &:before {
                background-color: rgba($black, 0.35);
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                opacity: 0;
                transition: all ease-in-out 0.3s;
            }

            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url("/assets/templates/images/svg/lightbox-button.svg");
                width: 86px;
                height: 85px;
                background-size: contain;
                content: '';

                opacity: 0;
                transition: all ease-in-out 0.3s;
            }

            &:hover,&:focus {
                &:before,&:after {
                    opacity: 1;
                }
            }
        }
    }
}
