@use "sass:map";

.pagetitle {
  padding-block: 50px 32px;
  background: $white-grey;

  @include media("<=large") {
    padding-block: 20px 32px;
  }

  @include media("<medium") {
    padding-block: 10px 15px
  }

  .row {
    margin-left: unset;
    margin-right: unset;
  }

  .medium-12 {
    grid-column: 1/12 span;
    display: flex;
    justify-content: center;

    h1 {
      font-size: rem-calc(60);
      font-weight: 400;
      line-height: 1;
      text-transform: uppercase;
      font-family: "astoria", sans-serif;
      color: $title-grey;
      margin-top: 16px;

      @include media("<=large") {
        font-size: rem-calc(40);
      }

      @include media("<medium") {
        font-size: rem-calc(28);
      }
    }
  }

  &:has(.pagetitle__person) {
    .medium-12 {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-column: 2 / 12 span;

      @include media("<medium") {
        grid-column: 1/ 12 span;
      }
    }
  }

  &__person {
    margin-top: 25px;
    margin-bottom: 50px;
    strong {
      font-size: 18px;
      font-weight: 500;
      margin-left: 10px;

      span {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }

  &.cta {
    padding-block: 90px 0;

    .pagetitle {
      &__align {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 991px) {
          flex-direction: column;
        }

        >div:first-of-type {
          margin: auto 0;
        }
 
        h1 {
          margin-top: 10px;
        }
      }
    }

    .grid > div {
      justify-content: start;
      grid-column: 2/12 span;
    }
    
    .grid .grid:has(.breadcrumbs) {
      display: block;

      @media (min-width: 1921px) {
        left: 0;
      }
    }

    .cta {
      background-color: #BAB9AB;
      position: relative;
      z-index: 1; 
      padding: 25px;
      overflow: hidden;
      width: 45%;

      &:after {
        background-image: url('/assets/templates/images/svg/gray-cta-bg.svg') !important;
      }

      @media (max-width: 991px) {
        width: 97%;
        margin-top: 25px;
      }

      @include media(">=xxlarge") {
        padding: 50px;
      }
 
      a {
        color: #fff;
      }

      .link-icon:before {
        filter: brightness(0) saturate(100%) invert(56%) sepia(0%) saturate(749%) hue-rotate(354deg) brightness(95%) contrast(93%);
      }

      span {
        font-size: rem-calc(18);
        font-weight: 500;
        text-transform: uppercase;
        color: #707070;
      }
  
      h2 {
        font-size: rem-calc(25);
        color: #fff;
        max-width: 222px;
        line-height: 30px;
        margin-bottom: 15px;

        @include media(">=xxxlarge") {
          max-width: 422px;
          line-height: 32px;
        }
      }

      .btn-black {
        @include media("<=large") {
          font-size: .7rem;

          &:before {
            top: 15px;
          }
        }

        @include media("<=xxxlarge") {
          font-size: .9rem;
        }
      }

      h2,span,a {
        z-index: 2;
        position: relative;
      }

      &:after {
        background-image: url('/assets/templates/images/svg/yellow-cta-bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        position: absolute;
        content: '';
        top: 0;
        right: 75px;
        height: 100%;
        width: 100%;

        @media (min-width: 1730px) {
          right: 100px;
        }
      }
    }
  
    .page-intro-homepage__cta-image {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
    }
  }
}