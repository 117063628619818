@use "sass:map";

.project-filters {
  padding-block: 36px 60px;

  ul {
    grid-column: 2 /11 span;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 70px;
    justify-content: center;

    @include media("<=large") {
      grid-column: 1/ 12 span;
      gap: 20px;
      flex-wrap: wrap;
      padding-inline: 16px;
    }


    li {
      input {
        width: 0;
        height: 0;
        appearance: none;
        margin: 0;
      }

      input:checked + label {
        font-weight: 600;
        border-bottom: 1px solid $orange;
      }

      a,label {
        font-size: rem-calc(16);
        line-height: 1;
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: 8px;
        border-bottom: 1px solid transparent;
        position: relative;
        cursor: pointer;

        &.is-active {
          font-weight: 600;
          border-bottom: 1px solid $orange;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 0;
          height: 1px;
          background-color: $black;
          transition: width ease-in-out 0.3s;
        }

        &:hover, &:focus {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}