@use "sass:map";

.logo-slider {
  background: $white;
  border-left: 1px solid $white-grey;
  overflow: hidden;
  background-color: #F4F4F1;

  .slider-container {
    grid-column: 1/12 span;
    padding-block: 50px 50px;
    border-left: 2px solid $white-grey;
    position: relative;
    left: -18px;

    @include media("<medium") {
      border-left: unset;
      grid-column: 1 / 12 span;
      position: static;
      padding-block: 0 114px;
      padding-inline: 16px 0;
    }

    .swiper {
      margin: 0;
    }
  }
}