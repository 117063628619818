@use "sass:map";

.image-with-text {
  padding-block: 0 0;
  background: $white;
  border-left: 1px solid $white-grey;


  .container {
    grid-column: 2 / 10 span;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include pim();
    @include media("<=large") {
      grid-column: 1/12 span;
    }

    @include media("<medium") {
      flex-direction: column;
      justify-content: unset;
    }

    picture {
      width: 50%;
      @include media("<medium") {
        width: 100%;
      }
    }

    .text-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        max-width: 536px;
        position: relative;
        top: 9px;

        h2 {
          color: $red;
          font-family: "astoria", sans-serif;
          font-weight: 400;
          font-size: rem-calc(28);
          line-height: 1.25;
          text-transform: uppercase;
          margin-bottom: 0;

          @include media("<=large") {
            font-size: rem-calc(24);
            margin-bottom: 15px;
          }

          @include media("<medium") {
            font-size: rem-calc(20);
          }
        }

        p {
          font-weight: 500;

          @include media("<medium") {
            font-size: rem-calc(16);
          }
        }
      }
    }
  }

  &.dir-right {
    .container {
      flex-direction: row-reverse;
      grid-column: 2 / 10 span;
      @include media("<=large") {
        grid-column: 1/12 span;
      }

      @include media("<medium") {
        flex-direction: column;
      }

      .text-container {
        .text {
          max-width: 565px;

          p {
            max-width: 536px;
          }
        }
      }
    }
  }

  + .image-with-text {
    @include media("<medium") {
      padding-top: 30px;
    }
  }

  + .faq {
    margin-top: 151px;

    @include media("<=large") {
      margin-top: 100px;
    }

    @include media("<medium") {
      margin-top: 30px;
    }
  }

  .highlight-text {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;

    @include media("<medium") {
      gap: 28px;
      margin-bottom: 45px;
    }

    .hightlight-character {
      border-right: 2px solid $letter-brown;
      overflow: hidden;
      flex: 0 0 auto;
      max-height: 149px;
      position: relative;
      left: -5px;

      @include media("<medium") {
        left: -1px;
        max-height: 80px;
      }

      .character {
        color: $letter-brown;
        font-size: rem-calc(130);
        font-weight: 600;
        line-height: 1;
        width: 120px;
        position: relative;
        top: -3px;

        @include media("<medium") {
          font-size: rem-calc(70);
          top: -5px;
          width: 68px;
        }
      }
    }

    p {
      font-size: rem-calc(27);
      font-weight: 500;
      line-height: 1.51;
      margin-bottom: 0;
      color: $black;

      @include media("<medium") {
        font-size: rem-calc(18);
        line-height: 1.55;
      }
    }
  }
}

section.image-with-text:first-of-type {
  padding-top: 25px;
}

section.image-with-text:has(+ section.form-afspraak-maken) {
  padding-bottom: 100px;
}

section.image-with-text:nth-child(even) .text-container {
  padding-left: 100px;
}

section.image-with-text .text-container {
  padding-top: 50px;
  padding-bottom: 50px;
}

section.image-with-text:nth-child(odd) .text-container {
  padding-right: 100px;
}

.image-with-text .container {
  gap: 0;
}

.image-with-text .container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-with-text .container .text-container {
  width: 50%;
}

@include media("<medium") {
  section.image-with-text .text-container {
    padding: 50px 0 !important;
    justify-content: start;
  }

  .image-with-text .container .text-container {
    width: 100%;
  }
}