@use "sass:map";

.full-width-image {
    background-size: cover;
    height: 90vh;
    width: 100%;
    position: relative;
    z-index: 4;
    background-position: center;
    margin-top: -8px;
    max-height: 842px;
    @include parallax-bg();

    @include media("<medium") {
        padding-top: 114px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/assets/templates/images/svg/home-banner-overlay.svg");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;

        @include media("<medium") {
            background-image: url("/assets/templates/images/svg/home-banner-mobile-overlay.svg");
        }
    }
}