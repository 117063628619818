.footer {
  background-color: $black;
  background-image: url("/assets/templates/images/svg/footer-bg.svg");
  background-size: 200px 540px;
  background-repeat: no-repeat;
  background-position: right center;
  padding-block: 206px 104px;

  @include media("<=large") {
    padding-block: 64px 50px;
    background-size: 121px 325px;
    background-position: right 85%;
  }

  &-container {
    //display: flex;
    //flex-flow: row wrap;
    margin: unset;

    @include media(">1920px") {
      margin-left: auto;
      margin-right: auto;
      max-width: unset;

    }
  }

  .logo {
    position: relative;
    left: -43px;
    top: -11px;

    @include media("<=large") {
      position: unset;
      img {
        max-width: 189px;
      }
    }
  }

  .text {
    position: relative;
    top: 0px;
    left: -5px;

    @include media("<=large") {
      position: static;
      margin-top: 50px;
    }
  }

  .menu-container {
    display: flex;
    justify-content: center;
    position: relative;
    right: 6px;
    //flex-direction: column;

    @include media("<=large") {
      position: static;
      display: block;
      margin-top: 16px;

      &.open {
        h2:after {
          transform: rotate(180deg);
        }

        .menu-items ul {
          display: flex;
        }
      }
    }

    .menu-items {
      @include media("<=large") {
        border-top: 1px solid $footer-border;
        border-bottom: 1px solid $footer-border;
        padding-block: 11px 8px;
        padding-inline: 16px;
        margin-inline: -16px;
      }

      h2 {
        display: block;
        width: 100%;
        margin-bottom: 19px;

        @include media("<=large") {
          margin-bottom: 0;

          position: relative;
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 8px;
            background-image: url("/assets/templates/images/svg/icon-chevron-down-footer.svg");
            width: 19px;
            height: 10px;
            background-size: contain;
            background-repeat: no-repeat;


          }
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        @include media("<=large") {
          display: none;
        }

        li {
          display: inline-block;
          margin-bottom: 15px;

          a {
            font-size: rem-calc(18);
            font-weight: 400;
            position: relative;
            padding: 0;
            color: $white;

            &:before, &:after {
              content: '';
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: -7px;
              left: 0;
              right: 0;
              background: $footer-title;
            }

            &:after {
              width: 0;
              background: $orange;
              transition: width;
            }

            &:hover, &:focus {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  h2 {
    font-size: rem-calc(20);
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    color: $footer-title;
    //margin-bottom: 0;
    letter-spacing: 0.4px;
    margin-bottom: 17px;

    @include media("<=large") {
      font-size: rem-calc(18);
      line-height: 1.667;
      margin-bottom: 9px;
    }
  }

  p, address {
    font-size: rem-calc(18);
    font-style: normal;
    font-weight: 400;
    line-height: 1.833;
    color: #fff;

    @include media("<=large") {
      font-size: rem-calc(16);
      line-height: 1.75;
    }
  }

  .address-container {
    position: relative;
    left: 4px;

    @include media(">medium", "<=large") {
      border-top: 1px solid $footer-border;
      margin-top: 16px;
    }

    @include media("<=large") {
      position: static;
      //border-top: 1px solid $footer-border;
      border-bottom: 1px solid $footer-border;
      padding-block: 11px 8px;
      padding-inline: 16px;
      margin-inline: -16px;


      &.open {
        h2:after {
          transform: rotate(180deg);
        }

        .address-info {
          display: block;
        }
      }

      .address-info {
        display: none;
      }

      h2 {
        margin-bottom: 0;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 8px;
          background-image: url("/assets/templates/images/svg/icon-chevron-down-footer.svg");
          width: 19px;
          height: 10px;
          background-size: contain;
          background-repeat: no-repeat;


        }
      }
    }

    address {
      line-height: 1.667;
      margin-bottom: 29px;

    }
  }

  address + ul {
    li {
      margin-bottom: 16px;

      a {
        font-size: rem-calc(18);
        font-weight: 400;
        position: relative;
        padding: 0;
        color: $white;

        &:before, &:after {
          content: '';
          width: 100%;
          height: 1px;
          position: absolute;
          bottom: -7px;
          left: 0;
          right: 0;
          background: $footer-title;
        }

        &:after {
          width: 0;
          background: $orange;
          transition: width;
        }

        &:hover, &:focus {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .bottom-footer {
    display: flex;
    gap: 49px;
    align-items: center;
    padding-block: 192px 0;
    position: relative;
    left: -5px;

    @include media("<=large") {
      position: static;
      padding-block: 50px 0;
      flex-direction: column;
      align-items: unset;
      gap: 47px;
    }
  }


  .social-links {
    display: flex;
    flex-direction: row;
    gap: 16px;

    li {
      a {
        background: $social-icon-bg;
        width: 43px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover, &:focus {
          i {
            color: $orange;
          }
        }

        i {
          color: $social-icon-color;
        }
      }
    }
  }

  .footer-links {
    display: flex;
    flex-direction: row;
    gap: 25px;

    @include media("<=large") {
      flex-direction: column;
      gap: 10px;
    }

    li:first-child {
      @include media("<=large") {
        order: 4;
        margin-top: 35px;

        span {
          strong {
            display: block;
          }
        }
      }
    }

    span, a {
      font-size: rem-calc(18);
      font-weight: 400;
      color: $footer-title;

      strong {
        font-weight: 700;
        padding-right: 31px;
      }
    }

    a {
      &:hover, &:focus {
        color: $white;
      }
    }
  }

  .link-icon-horse {
    display: none;

    @include media("<=large") {
      display: block;
    }
  }
}