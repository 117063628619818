@use "sass:map";

.header-inrijpoorten {
  padding-block: 72px 20px;

  @include media("<=large") {
    padding-top: 50px;
  }

  @include media("<medium") {
    padding-block: 30px 20px;
  }

  .inrijpoorten-container {
    grid-column: 2 /11 span;
    display: flex;
    justify-content: space-between;
    gap: 30px;

    display: grid;
    gap: 16px;
    grid-template-columns: repeat(12, 1fr);
    max-width: 1640px;


    @include pim();

    @include media("<=large") {
      grid-column: 1/ 12 span;
    }

    @include media("<medium") {
      flex-direction: column;
    }


    .page-info {
      grid-column: 1 / 8 span;

      @include media("<=xxlarge") {
        grid-column: 1 / 10 span;
      }

      @include media("<medium") {
        grid-column: 1 / 12 span;
      }

      h1 {
        font-size: rem-calc(60);
        line-height: 1;
        font-weight: 400;
        color: $title-grey;
        text-transform: uppercase;
        font-family: "astoria", sans-serif;
        margin-top: 17px;
        margin-bottom: 1rem;

        @include media("<=large") {
          font-size: rem-calc(40);
          //margin-bottom: 40px;
        }

        @include media("<medium") {
          font-size: rem-calc(32);
          //margin-bottom: 25px;
        }
      }

      p {
        color: #1B1C1C;
        font-weight: 500;
      }
    }

    .select {
      display: flex;
      align-items: end;
      margin-bottom: 19px;
      padding-inline: 20px 0;
      position: relative;
      grid-column: 12;
      align-items: baseline;
      margin-top: 40px;

      @include media('<medium') {
        display: none;
      }

      &:before {
        content: '';
        background-image: url('/assets/templates/images/svg/icon-select.svg');
        width: 10px;
        height: 10px;
        position: absolute;
        left: -4px;
        background-repeat: no-repeat;
        bottom: 6px;
        top: 5px;
      }

      .ui-widget {
        border: unset;
        border-radius: unset;
        padding: 0;
        text-align: unset;
        width: unset;
        margin: 0;

        .ui-icon {
          display: none;
        }
      }

      .ui-selectmenu-text {
        font-weight: 600;
        font-size: rem-calc(16);
        line-height: 1.25;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        color: $black;
        margin: 0;
      }
    }
  }
}

.btn-scroll-to-overview {
  appearance: none;

  img {
    rotate: 0.25turn;
    object-position: 0px 23px;
  }
}