@mixin btn() {
  color: $white;
  background: $orange;
  text-transform: uppercase;
  font-size: rem-calc(16);
  font-weight: 600;
  line-height: 1.25;
  padding-block: 12.5px;
  padding-inline: 51px 21px;
  position: relative;
  letter-spacing: 0.45px;
  transition: all ease-in-out 0.3s;
  text-decoration: none;

  &:before {
    content: '';
    position: absolute;
    background-image: url('/assets/templates/images/svg/btn-icon.svg');
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 18px;
    left: 26px;
  }

  &:hover, &:focus {
    background: $dark-orange;
    color: $white;
  }
}

@mixin link-icon($text-color: $black) {
  font-size: rem-calc(16);
  font-weight: 600;
  letter-spacing: 0.45px;
  line-height: 1;
  text-transform: uppercase;
  padding-left: 29px;
  transition: padding-left ease-in-out 0.3s;
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 12px;
    height: 11px;
    background-image: url("/assets/templates/images/svg/icon-btn-grey.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover, &:focus {
    padding-left: 36px;

    &::before {
      background-image: url("/assets/templates/images/svg/icon-btn-orange.svg");
    }
  }


}

@mixin link($text-color: $black, $background-color: $footer-title, $active-background-color: $orange) {
  font-size: rem-calc(18);
  font-weight: 400;
  position: relative;
  padding: 0;
  color: $text-color;

  &:before, &:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
    background: $background-color;
  }

  &:after {
    width: 0;
    background: $active-background-color;
    transition: width;
  }

  &:hover, &:focus {
    &:after {
      width: 100%;
    }
  }
}

.btn {
  @include btn();
}

.span-btn {
  @include btn();

  &:before {
    display: none;
  }

  background: $orange;

  &:hover, &:focus {
    background: $dark-orange;
  }

  span {
    @include btn();
  }
}

.btn-transparent {
  @include btn();
  background: rgba($white, 0.2);
  backdrop-filter: blur(12px);
  white-space: nowrap;

  &:before {
    background-image: url('/assets/templates/images/svg/btn-icon-transparent.svg');
  }

  &:hover, &:focus {
    background: $orange;
    backdrop-filter: unset;

    &:before {
      background-image: url('/assets/templates/images/svg/btn-icon.svg');
    }
  }
}

.btn-alt {
  @include btn();
  background: $btn-alt-bg;
  color: $black;

  &:before {
    background-image: url('/assets/templates/images/svg/icon-btn-alt.svg');
  }

  &:hover, &:focus {
    background: $orange;
    color: $white;

    &:before {
      background-image: url('/assets/templates/images/svg/btn-icon.svg');
    }
  }
}

.btn-form {
  @include btn();
  background-color: $brown;
  color: $white;

  &:before {
    background-image: url('/assets/templates/images/svg/icon-btn-brown.svg');
  }

  &:hover, &:focus {
    background-color: #62140C;

    &:before {
      background-image: url('/assets/templates/images/svg/icon-btn-form-hover.svg');
    }
  }
}

.link-icon, .link-icon-variant {
  @include link-icon();
}

.link-icon-variant {
  &::before {
    background-image: url("/assets/templates/images/svg/icon-btn-orange.svg");
  }

  &:hover {
    &:before {
      background-image: url("/assets/templates/images/svg/icon-btn-grey.svg");
    }
  }
}

.btn-download {
  background-color: $black;
  color: $white;
  position: relative;
  font-size: rem-calc(16);
  line-height: 1.25;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 14px 20px 12px 52px;

  @include media("<medium") {
    padding: 13px 27px 12px 63px
  }

  &:before {
    content: '';
    width: 12px;
    height: 15px;
    background-image: url("/assets/templates/images/svg/icon-download.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 16px;
    left: 20px;

    @include media("<medium") {
      top: 15px;
      left: 30px;
    }
  }

  &:hover, &:focus {
    background: $orange;
  }
}

.btn-black {
  background-color: $black;
  color: $white;
  position: relative;
  font-size: rem-calc(16);
  line-height: 1.25;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding-block: 12.5px;
  padding-inline: 43px 15px;

  &:before {
    content: '';
    position: absolute;
    background-image: url('/assets/templates/images/svg/btn-icon.svg');
    width: 10px;
    height: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 18px;
    left: 22px;
  }

  &:hover, &:focus {
    background: $orange;
  }
}