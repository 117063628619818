@use "sass:map";

.our-team {
  padding-block: 137px 100px;

  .our-team-container {
    grid-column: 2 /11 span;

    @include media("<=large") {
      grid-column: 1 /12 span;
      padding-inline: $mobile-padding-inline;
    }

    h2 {
      color: $title-grey;
      font-size: rem-calc(45);
      font-weight: 400;
      line-height: 1.33;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 37px;
      font-family: "astoria", sans-serif;
    }

    ul {
      list-style: none;
      margin: 0;
      display: flex;
      gap: 51px;
      flex-wrap: wrap;

      @include media("<=large") {
        gap: 30px;
      }

      @include media("<=800") {
        justify-content: space-between;
      }

      @include media("<medium") {
        gap: 25px
      }

      li {
        display: flex;
        background: $white;
        flex-direction: column;
        padding-block: 44px 0;
        padding-inline: 42px 0;
        max-width: 337px;
        width: 100%;
        justify-content: space-between;

        h3, span {
          font-weight: 600;
          font-size: rem-calc(25);
          line-height: 1.2;
          color: $black;
        }

        h3 {
          margin-bottom: 0;
        }

        span {
          font-weight: 400;
          margin-bottom: 10px;
        }

        picture {
          align-self: flex-end;
        }
      }
    }
  }
}