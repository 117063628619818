@use "sass:map";

/*
 * Styling for the header
 */
.header {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 30;
  //border-bottom: 1px solid #000;
  background-color: transparent;

  @include media("<=large") {
    padding: 0;
    position: sticky;
  }

  .header-new-menu {
    background-color: $white;
  }

  .header__logo {
    .dark {
      display: block;
    }

    .light {
      display: none;
    }
  }

  .header .shield {
    top: 90px;

    @include media("<=large") {
      top: 28px;
    }
  }

  .header__nav ul li a,
  .header__nav .header-review-button .google-review strong {
    color: #0a0a0a;
  }

  .header__nav .header-review-button .google-review strong i {
    color: rgb(112, 112, 112, .5)
  }

  .header__wrapper {
    padding-bottom: 10px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 27px;
      width: 100%;
      bottom:-25px;
      left: 0;
      background-image: url("/assets/templates/images/svg/lijntje-parallax-banner.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .pagetitle {
    padding-block: 50px 32px;
  }

  &.mobile-bg {
    @include media("<=large") {
      background-color: rgba(255,255,255,1);
    }
    .header-container .header__wrapper .header__logo .dark {
      display: block;
    }
    .header-container .header__wrapper .header__logo .light {
      display: none;
    }
  }

  .btn-transparent {
    @include media("<1280px") {
      display: none;
    }
  }

  .header-container {
    max-width: 1640px;
    margin-right: 16px;

    @include media("<=large") {
      margin-right: 0;
    }

    @include media(">1920px") {
      max-width: unset;
    }
  }

  .shield {
    position: fixed;
    top: 100px;
    left: 105px;
    z-index: 1;

    @include media("<xxlarge") {
      left: 34px;
    }

    @include media("<=large") {
      top: 28px;
      width: 40px;
      left: 17px;
    }

    @include media('<medium') {
      //width: 40px;
    }
  }

  .header__nav-mobile {
    display: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @include media(">=large") {
      top: 35px;
    }
    
    @include media("<=large") {
      padding-inline: 17px;
      padding-block: 28px;
    }

    @include media(">1920px") {
      margin-right: 140px;
    }
  }

  &__logo {
    margin: auto 0;
    position: relative;
    left: -35px;
    z-index: 11;

    @include media("<=large") {
      left: 0;
      top: 0;
    }

    .dark {
      display: none;
    }

    img {
      @include media('<=large') {
        width: 168px;
      }
    }
  }

  &__nav {
    display: flex;
    gap: 65px;
    align-items: center;
    position: relative;

    @include media('<1920px') {
      gap: 50px;
    }

    @include media('<1600px') {
      justify-content: space-between;
      gap: 50px;
    }

    @include media("<xxlarge") {
      gap: 40px;
    }

    @include media("<=large") {
      padding-right: 0;
      display: none;
    }

    ul {
      display: flex;
      gap: 19px; 
      flex-wrap: nowrap;
      position: relative;
      left: 2px;

      @include media('<=large') {
        margin-right: 0;
        margin-top: 0;
        gap: 20px;
        flex-direction: column;
      }

      li {
        display: inline-block;
        margin: 0;
        position: relative;

        &:before {
          content: '';
          background-image: url("/assets/templates/images/svg/icon-menu.svg");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 18px;
          left: 0;
          width: 10px;
          height: 10px;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }


        @include media('<=large') {
          display: block;
          text-align: center;
        }

        &.has-children {
          padding-right: 24px;
          padding-bottom: 60px;

          &:after {
            content: '';
            width: 12px;
            height: 7px;
            background-image: url('/assets/templates/images/svg/chevron-down.svg');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 18px;
            right: 0;
            transition: all 0.3s ease-in-out;
          }

          &:hover, &:focus, &.hover {
            &:after {
              transform: rotate(180deg);
            }
          }
        }

        ul {
          display: none;
          z-index: 999;
        }

        a {
          font-size: rem-calc(18);
          color: $white;
          font-weight: 500;
          //padding: 10px 0;
          padding: 13px 0 0;
          text-transform: uppercase;
          //white-space: nowrap;

          @include media("<1640px") {
            font-size: rem-calc(16);
          }
        }


        &:hover, &:focus, &.hover {
          &:before {
            left: -13px;
            opacity: 1;
          }

          &.has-children {
            ul:not(.product-overview) {
              background: $white;
              padding-block: 24px 36px;
              padding-inline: 55px;
              display: flex;
              flex-direction: column;
              gap: 4px;
              margin: 0;
              position: absolute;
              top: 71px;
              left: -40px;
              //max-height: 0;
              opacity: 0;
              animation: submenuAnimation 0.5s;
              animation-delay: 0.2s;
              animation-fill-mode: forwards;

              li {
                position: relative;

                &:before {
                  content: '';
                  background-image: url("/assets/templates/images/svg/icon-menu.svg");
                  background-repeat: no-repeat;
                  background-size: contain;
                  position: absolute;
                  top: 21px;
                  left: 0;
                  width: 10px;
                  height: 9px;
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                }

                &:hover, &:focus {
                  &:before {
                    left: -13px;
                    opacity: 1;
                  }
                }

                a {
                  color: $black;
                  font-size: rem-calc(18);
                  text-transform: uppercase;
                  line-height: 1.277;
                  font-weight: 500;
                }
              }
            }
          }

          ul.product-overview {
            display: flex;
            opacity: 1;
            background: $white;
            padding: 30px;
            gap: 29.6px;
            position: fixed;
            top: 145px;
            width: 100%;
            max-width: 1159px;
            left: calc(50% - calc(1167px / 2));
            opacity: 0;
            animation: submenuAnimation 0.5s;
            animation-delay: 0.2s;
            animation-fill-mode: forwards;

            li {
              &:before {
                display: none;
              }

              a {
                padding: 0;

                &:hover, &:focus {
                  span {
                    &:after {
                      opacity: 1;
                      right: -44px;
                    }
                  }
                }

                span {
                  font-size: rem-calc(24);
                  font-weight: 600;
                  line-height: 1.125;
                  color: $white;
                  text-transform: uppercase;
                  max-width: 130px; //117px;
                  font-family: 'astoria', sans-serif;
                  position: absolute;
                  bottom: 29px;
                  left: 30px;
                  display: inline-block;

                  &:after {
                    content: '';
                    background-image: url('/assets/templates/images/svg/product-overview-icon.svg');
                    width: 32px;
                    height: 31px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    right: 0;
                    bottom: -2px;
                    transition: all ease-in-out 0.3s;
                    opacity: 0;
                  }
                }

                picture {
                  display: block;

                  &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(rgba(0, 0, 0, 0), $black);
                  }
                }
              }
            }
          }
        }

      }
    }

    .header-review-button {
      display: flex;
      max-height: 45px;
      gap: 15px;
      position: relative;

      @include media("<1460px") {
        display: none;
      }

      &::before {
        position: absolute;
        content: '';
        left: -30px;
        bottom: 1px;
        width: 2px;
        height: 85%;
        background-color: rgb(255,255,255,.3);
      }

      .google-review {
        a {
          strong {
            color: #fff;
            font-size: 24px;
            display: block;
            text-align: center;
            line-height: 1;

            @include media("<=xxlarge") {
              font-size: 16px;
            }

            i {
              color: rgb(255,255,255,.4);
              font-size: 16px;
              font-style: normal;
              vertical-align: top;
            }
          }
        }
        ul {
          gap: 0;
          margin: 0;

          li {
            img {
              max-height: 10px;
            }
          }

          li:before {
              content: unset;
          }
        }
      }

      .btn {
        @media (max-width: 1400px) {
          padding-right: 1px;
        }
      }
    }
  }

  &__menutrigger {
    display: none;
    border-radius: 50px;
    background-color: $orange;
    width: 51px;
    height: 51px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
    position: fixed;
    top: 31px;
    right: 17px;

    .icon {
      display: block;
      height: 3px;
      width: 50%;
      margin: 0 auto;
      position: relative;
      transition: padding .15s ease-out, height .15s ease-out;

      &::before,
      &::after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background: #FFF;
        transition: transform .15s ease-out;
      }

      &::before {
        top: -6px;
      }

      &::after {
        top: 6px;
      }

      .base-line {
        display: block;
        width: 100%;
        height: 100%;
        background: #FFF;
        transition: background-color .15s ease-out;
      }
    }

    &:hover, &:focus {
      .icon {
        padding-left: 15%;
        padding-right: 0;
      }
    }

    @include media('<=large') {
      display: flex;
    }
  }

  &.dark {
    .header__logo {
      .light {
        display: none;
      }

      .dark {
        display: block;
      }
    }

    .header__nav {
      ul {
        li {

          &.has-children {
            &:after {
              background-image: url("/assets/templates/images/svg/chevron-down-black.svg");
            }
          }

          a {
            color: $black;
          }
        }
      }

      .btn-transparent {
        background-color: $orange;
        color: $white;

        &:before {
          background-image: url('/assets/templates/images/svg/btn-icon.svg');
        }

        &:hover, &:focus {
          background: $dark-orange;
          color: $white;

        }


      }
    }
  }

  &.landingspage {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -65px;
      left: 0;
      right: 0;
      background-image: url("/assets/templates/images/svg/path-line-landingspage.svg");
      width: 100%;
      height: 27px;
      background-repeat: no-repeat;
      background-size: cover;

      @include media("<=large") {
        bottom: -30px;
      }
    }

    .header-container {
      @include media(">1920px") {
        max-width: unset;
      }
    }

    .header__wrapper {
      @include media("<medium") {
        gap: 15px;
      }
    }

    .header__nav {
      position: static;

      @include media("<=large") {
        display: flex;
      }

    }

    .btn-arrow-back {
      background-color: unset;
      color: $white;
      font-size: rem-calc(16);
      font-weight: 600;
      line-height: 1.25;
      text-transform: uppercase;
      letter-spacing: 0.32px;
      position: relative;
      padding-left: 51px;
      transition: all ease-in-out 0.3s;

      @include media("<medium") {
        font-size: rem-calc(14);
        padding-left: 30px
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        background-image: url(/assets/templates/images/svg/arrow-back.svg);
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        transition: all ease-in-out 0.3s;
      }

      &:hover, &:focus {
        padding-right: 10px;

        &:before {
          top: -6px;
          width: 33px;
          height: 33px;
        }
      }

    }
  }

  &.dark {
    .btn-arrow-back {
      color: $black;

      &::before {
        background-image: url(/assets/templates/images/svg/arrow-back-black.svg);
      }
    }

    .header__nav ul li a {
      background: transparent;
    }
  }
}

body.menu-open {
  .header__wrapper {
    background: $white-grey;

    .header__logo {
      .light {
        display: none;
      }

      .dark {
        display: block;
      }
    }
  }

  .header__menutrigger {
    .icon {
      &:before, &:after {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      span {
        display: none;
      }
    }
  }

  .header__nav-mobile {
    display: flex;
    flex-direction: column;
    background: $white;
    position: fixed;
    top: 95px;
    height: calc(100dvh - 114px);
    width: 100%;
    overflow-y: scroll;

    .top-menu, .second-menu {
      padding-block: 42px;

      ul {
        margin: 0;
        padding-inline: 16px;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 23px;

        li {
          position: relative;

          &:before {
            content: '';
            background-image: url("/assets/templates/images/svg/icon-menu.svg");
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 9px;
            left: 0;
            width: 12px;
            height: 11px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            padding-left: 0;
          }

          &:hover, &:focus, &.hover {
            &:before {
              opacity: 1;
            }

            a {
              padding-left: 27px;
            }
          }

          &.has-children {
            width: fit-content;

            > a {
              position: relative;
              padding-right: 31px;
              &:after {
                content: '';
                width: 18px;
                height: 10px;
                background-image: url('/assets/templates/images/svg/chevron-down-black.svg');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                top: 11px;
                right: 0;
                transition: all 0.3s ease-in-out;
              }
            }


            &:hover, &:focus, &.hover {
             > a:after {
                transform: rotate(180deg);
              }

              ul {
                display: flex;
              }
            }

            ul {
              display: none;
              padding-block: 11px 0;
              padding-inline: 10px;
              flex-direction: column;
              gap: 7px;

              li {
                a {
                  text-transform: unset;
                  font-size: rem-calc(18);
                  letter-spacing: 0.1px;
                }
              }
            }
          }

          a {
            font-size: rem-calc(24);
            line-height: 1.33;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 0.4px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    .second-menu {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #575756;
        top: 0;
        left: 0;
      }
    }

    .bottom-menu {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: auto;

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 7px;
        margin: 0;
        padding-inline: 16px;
        padding-block: 28px;

        li {
          position: relative;

          &:before {
            content: '';
            background-image: url("/assets/templates/images/svg/icon-menu.svg");
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 9px;
            left: 0;
            width: 12px;
            height: 11px;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            padding-left: 0;
          }

          &:hover, &:focus, &.hover {
            &:before {
              opacity: 1;
            }

            a {
              padding-left: 27px;
            }
          }

          a {
            font-weight: 400;
            font-size: rem-calc(18);
            line-height: 1.667;
            color: $black;
            transition: all 0.3s ease-in-out;

          }
        }
      }

      .span-btn {
        padding: 0;

        span {
          padding-block: 20px;
          padding-inline: 37px 21px;
          display: flex;
          justify-content: center;
          //padding: 0;

          &:before {
            position: relative;
            top: 5px;
            left: -17px;
          }
        }
      }
    }
  }
}


.sticky-header-wrapper {
  max-width: 1640px;
  margin-right: 16px;
  padding-block: 51px 30px;
  width: 100%;
  margin-left: 140px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 3;

  @include media(">1920px") {
    max-width: unset;
    margin-left: 0;
    margin-left: -32px;
  }

  &.hide {
    display: none;
  }

  &.show {
    display: flex;
  }

  @include media("<=1795px") {
    margin-left: 0;
  }

  @include media("<=large") {
    display: none;
  }


  .sticky-menu-buttons {
    display: flex;

    @include media("<global-width") {
      margin-right: 16px;
    }

    .btn {
      padding-inline: 48px 21px;
    }

    button {
      appearance: none;
      background-color: #C88C07;
      padding: 12.5px 15px;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      gap: 14px;

      &:hover, &:focus {
        gap: 11px;
        padding-left: 18px;

        .icon {
          &:before {
            top: -6px;
          }

          &:after {
            top: 6px;
          }
        }
      }

      span.text {
        color: $white;
        text-transform: uppercase;
        font-family: "bilo", sans-serif;
        font-size: rem-calc(16);
        letter-spacing: 0.48px;
        line-height: 1.25;
        font-weight: 500;
      }

      .icon-container {
        flex: 0 0 25px;
      }

      .icon {
        display: block;
        height: 2px;
        width: 25px;
        //padding-right: 15%;
        margin: 0 auto;
        position: relative;
        transition: padding .15s ease-out, height .15s ease-out;


        &::before,
        &::after {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background: #FFF;
          transition: transform .15s ease-out;
        }

        &::before {
          top: -7px;
        }

        &::after {
          top: 7px;
        }

        .base-line {
          display: block;
          width: 100%;
          height: 100%;
          background: #FFF;
          transition: background-color .15s ease-out;
        }
      }
    }
  }
}

.overlay-menu {
  display: none;
}

body.overlay-menu-open {
  overflow: hidden;

  @include media("<=large") {
    .overlay-menu {
      display: none !important;
    }
  }

  .header__nav {
    z-index: -1;
    display: none;
  }


  .sticky-header-wrapper {
    z-index: 999;
  }
  .sticky-menu-buttons {
    button {
      background-color: $brown;

      &:hover, &:focus {
        .icon {
          &:before, &:after {
            top: 0;
          }
        }
      }


      .icon {
        &:before, &:after {
          top: 0;
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }

        span.base-line {
          display: none;
        }
      }
    }
  }

  .header {
    position: fixed;
    z-index: 999;
    top: -20px;
  }

  .header__logo {
    z-index: 999;
    .dark {
      display: block;
    }

    .light {
      display: none;
    }
  }

  .header {
    //top: 176px;

    .shield {
     top: 29px;
    }
  }

  .overlay-menu {
    //background-color: $white;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 998;
    background-image: url("/assets/templates/images/menu-overlay-bg.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 125px;
    opacity: 0;
    animation: overlayMenuOpen 0.5s;
    animation-fill-mode: forwards;

    .site-container {
      background: unset;
    }


    .container {
      padding-top: 147px;
      grid-column: 2/11 span;
      display: flex;
      gap: 20px;
      justify-content: space-between;

      @include media("<=xlarge") {
        grid-column: 1/12 span;
        padding-inline: 16px;

      }

      + img {
        grid-column: 2/11 span;
        margin-block: 72px 29px;
      }

      .text-info {
        max-width: 324px;

        @include media("<=xxlarge") {
          max-width: 250px;
        }

        h2 {
          font-family: 'astoria', sans-serif;
          font-size: rem-calc(32);
          font-weight: 400;
          color: $black;
          line-height: 1;
          margin-bottom: 24px;
          text-transform: uppercase;
        }

        p {
          font-size: rem-calc(18);
          font-weight: 500;
          line-height: 1.6667;
          color: $black;
        }

        a {
          margin-top: 114px;
          letter-spacing: 0.35px;
          @include link-icon();
        }
      }

      ul.product-overview {
        display: flex;
        opacity: 1;
        background: $white;
        //padding: 30px;
        gap: 29.6px;
        //position: fixed;
        //top: 145px;
        //width: 100%;
        //max-width: 1159px;
        //left: calc(50% - calc(1167px / 2));
        list-style: none;
        margin: 0;
        margin-top: -5px;

        li {
          &:before {
            display: none;
          }

          a {
            padding: 0;
            position: relative;

            &:hover, &:focus {
              span {
                &:after {
                  opacity: 1;
                  right: -44px;
                }
              }
            }

            span {
              font-size: rem-calc(24);
              font-weight: 500;
              line-height: 1.125;
              color: $white;
              text-transform: uppercase;
              max-width: 130px; //117px;
              font-family: 'astoria', sans-serif;
              position: absolute;
              bottom: 29px;
              left: 30px;
              display: inline-block;

              @include media("<=xxlarge") {
                font-size: rem-calc(20);
                left: 10px;
                bottom: 10px;
              }

              &:after {
                content: '';
                background-image: url('/assets/templates/images/svg/product-overview-icon.svg');
                width: 32px;
                height: 31px;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                right: 0;
                bottom: -2px;
                transition: all ease-in-out 0.3s;
                opacity: 0;
              }
            }

            picture {
              display: block;
              position: relative;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                background: linear-gradient(rgba(0, 0, 0, 0), $black);
              }
            }
          }
        }
      }
    }

    .overlay-menu-items {
      grid-column: 2/11 span;
      margin: 0;
      list-style: none;
      display: flex;
      gap: 135px;

      li {
        display: inline-block;
        margin: 0;
        position: relative;

        &:before {
          content: '';
          background-image: url("/assets/templates/images/svg/icon-menu.svg");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 18px;
          left: 0;
          width: 10px;
          height: 10px;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }


        @include media('<=large') {
          display: block;
          text-align: center;
        }

        &.has-children {
          padding-right: 24px;
          padding-bottom: 60px;

          &:after {
            content: '';
            width: 14px;
            height: 8px;
            background-image: url('/assets/templates/images/svg/chevron-down-black.svg');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 18px;
            right: 0;
            transition: all 0.3s ease-in-out;
          }

          &:hover, &:focus, &.hover {
            &:after {
              transform: rotate(180deg);
            }
          }
        }

        ul {
          display: none;
          //z-index: 999;
        }

        a {
          font-size: rem-calc(28);
          color: $black;
          font-weight: 500;
          //padding: 10px 0;
          padding: 13px 0 0;
          text-transform: uppercase;

          @include media("<=large") {
            font-size: rem-calc(24);
          }
        }


        &:hover, &:focus, &.hover {
          &:before {
            left: -17px;
            opacity: 1;
          }

          &.has-children {
            ul {
              background: $white;
              padding-block: 24px 0;
              padding-inline: 55px 0;
              display: flex;
              flex-direction: column;
              gap: 15px 50px;
              max-height: 200px; //170px;
              flex-wrap: wrap;
              margin: 0;
              position: absolute;
              top: 55px;
              left: -57px;

              li {
                position: relative;

                &:before {
                  content: '';
                  background-image: url("/assets/templates/images/svg/icon-menu.svg");
                  background-repeat: no-repeat;
                  background-size: contain;
                  position: absolute;
                  top: 9px;
                  left: 0;
                  width: 10px;
                  height: 9px;
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                }

                &:hover, &:focus {
                  &:before {
                    left: -13px;
                    opacity: 1;
                  }
                }

                a {
                  color: $black;
                  font-size: rem-calc(18);
                  text-transform: uppercase;
                  line-height: 1.277;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }

    .site-container {
      height: 100%;
      border-left: 1px solid #E9E8E3;
    }
  }
}

@keyframes submenuAnimation {
  0% {
    //max-height: 0;
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    //max-height: 100vh;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes overlayMenuOpen {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header:has(~ main > .page-banner) .header__wrapper {
  border-bottom: none;
}