.contactperson__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contactperson {
        display: flex;
        gap: 20px;
        margin-top: 56px;
        position: relative;
        margin-bottom: 60px;

        @include media("<=large") {
            gap: 25px;
        }

        @include media("<medium") {
            margin-top: 30px;
            margin-bottom: 40px;
        }

        picture {
            border-radius: 50%;
            overflow: hidden;
            display: block;
            background-color: #DEDDD5;
            flex: 0 0 auto;
            height: 100%;

            @include media("<=large") {
                width: 100px;
                height: 100px;
            }
        }

        .info {
            margin-top: 5px;

            h2 {
                font-weight: 600;
                font-size: rem-calc(22);
                line-height: 1.36;
                color: $black;
                margin-bottom: 0;

                @include media("<medium") {
                    font-size: rem-calc(16);
                }
            }

            span {
                font-weight: 400;
                font-size: rem-calc(22);
                line-height: 1.36;
                color: $black;

                @include media("<medium") {
                    font-size: rem-calc(16);
                }
            }

            ul {
                list-style: none;
                margin: 0px;
                display: flex;
                flex-direction: column;
                margin-top: 15px;
                margin-left: 3px;
                gap: 15px;

                li {
                    a {
                        @include link();
                    }

                    &:last-child {
                        a {
                            font-size: rem-calc(17);
                        }
                    }
                }
            }
        }
    } 
}