@use "sass:map";

.gate-slider {
  background: $white;
  border-left: 1px solid $white-grey;
  overflow: hidden;


  .info-controls {
    grid-column: 2 /2 span;
    padding-block: 130px 200px;

    @include media(">=medium", "<=large") {
      grid-column: 2/1 span;
    }

    @include media("<medium") {
      grid-column: 1/12 span;
      border-top: 2px solid $white-grey;
      padding-inline: 16px;
      padding-block: 66px 42px;
    }

    h2 {
      color: $orange;
      font-family: "astoria", sans-serif;
      max-height: 268px;
      text-transform: uppercase;
      font-weight: 400;
      line-height: 1.25;
      writing-mode: tb-rl;
      font-size: rem-calc(32);
      transform: rotate(-180deg);
      position: relative;
      top: 103px;
      left: -3px;

      @include media("<medium") {
        transform: unset;
        writing-mode: unset;
        position: static;
        font-size: rem-calc(28);
        line-height: 1.43;
        max-height: unset;
      }
    }

    p {
      font-size: rem-calc(18);
      font-weight: 500;
      line-height: 1.389;
      color: $black;
      margin-top: 150px;
      max-width: 150px;
      margin-bottom: 47px;

      @include media("<medium") {
        max-width: unset;
        margin-top: 29px;
        margin-bottom: 29px;
      }
    }

    .btn-transparent {
      background: transparent;
      padding-right: 0;
      color: $black;

      @include media("<medium") {
        font-size: rem-calc(16);
      }

      &:hover, &:focus {
        background: transparent;
        padding-right: 0;
        color: $black;
      }
    }

    .link-icon {
      @include media("<medium") {
        font-size: rem-calc(16);
      }
    }
  }

  .arrows {
    display: flex;
    gap: 24px;
    //justify-content: space-between;
    margin-top: 140px;

    @include media("<medium") {
      margin-top: 32px;
      gap: 19px;
    }

    .arrow {
      width: 65px;
      height: 65px;
      background-color: $white-grey;
      font-size: 0;
      position: relative;
      overflow: hidden;

      @include media("<medium") {
        width: 48px;
        height: 48px;
      }

      &:before, &:after {
        content: '';
        background-size: contain;
        background-repeat: no-repeat;
        width: 65px;
        height: 65px;
        background-image: url('/assets/templates/images/svg/arrow.svg');
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        transition: left ease-in-out 0.3s;

        @include media("<medium") {
          width: 48px;
          height: 48px;
        }
      }

      &:before {
        left: -100%
      }


      &.arrow-next {

        &:focus, &:hover {
          &:before {
            left: 50%;
          }

          &:after {
            left: 150%;
          }
        }
      }

      &.arrow-prev {
        &:before, &:after {
          transform: translateY(-50%) translateX(-50%) rotate(180deg);
        }

        &:before {
          left: 50%;
        }

        &:after {
          left: 150%;
        }

        &:focus, &:hover {
          &:before {
            left: -100%;
          }

          &:after {
            left: 50%;
          }
        }
      }
    }
  }

  .slider-container {
    grid-column: 4 / 9 span;
    padding-block: 231px 0px;
    border-left: 2px solid $white-grey;
    position: relative;
    left: -18px;

    @include media("<medium") {
      border-left: unset;
      grid-column: 1 / 12 span;
      position: static;
      padding-block: 0 114px;
      padding-inline: 16px 0;
    }

    .swiper {
      @include media(">1784px") {
        position: absolute;
        width: calc(calc(100vw - 1784px) + 100.5%);
      }
    }

    .swiper-slide {
      max-width: 603px;
      height: auto;

      @include media("<medium") {
        max-width: 301px;
      }
    }

    .gate-container {
      background: $white-grey;
      padding: 68px 72px 70px 24px;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 693px;
      max-height: -webkit-fill-available;
      box-sizing: border-box;

      @include media("<medium") {
        padding: 29px 36px 35px 14px;
        max-height: 346px;
        max-height: -webkit-fill-available;

      }

      &:focus, &:hover {
        img {
          transform: scale(0.9);
        }
      }

      img {
        transform: scale(1);
        transition: all ease-in-out 0.3s;
      }

      h3 {
        color: $title-grey;
        font-size: rem-calc(55);
        font-weight: 500;
        //max-width: 280px;
        line-height: 1;
        letter-spacing: 1.1px;
        padding-inline: 46px;
        margin-bottom: 19px;
        flex: 1 0 auto;

        @include media("<medium") {
          font-size: rem-calc(32);
          line-height: 1;
          letter-spacing: 0.64px;
          padding-inline: 21px;
          margin-bottom: 4px;
        }
      }

      .link-icon {
        position: relative;
        left: 46px;
        display: inline-block;
        margin-top: 68px;

        @include media("<medium") {
          left: 21px;
          margin-top: 30px;
          font-size: rem-calc(16);
          padding-left: 21px;

        }
      }
    }
  }
}