@use "sass:map";

.page-intro-blogs-detail {
  background-color: $white-grey;

  .row, .grid-container {
    margin-left: unset;
    margin-right: unset;
  }

  .grid {
    @include grid();

    .mobile-image {
      @include media(">=medium") {
        display: none;
      }

      grid-column: 1 /12 span;
    }

    .text {
      padding-block: 0 147px;
      grid-column: 2 / 5 span;
      @include media("<medium") {
        padding-inline: 16px;
        padding-block: 0 42px;
      }

      @include media("<medium") {
        grid-column: 1 /12 span;
      }

      .highlight-text {
        display: flex;
        gap: 51px;
        margin-bottom: 50px;

        @include media("<medium") {
          gap: 28px;
          margin-bottom: 45px;
        }

        .hightlight-character {
          border-right: 2px solid $letter-brown;
          overflow: hidden;
          flex: 0 0 auto;
          max-height: 149px;
          position: relative;
          left: -5px;

          @include media("<medium") {
            left: -1px;
            max-height: 80px;
          }

          .character {
            color: $letter-brown;
            font-size: rem-calc(130);
            font-weight: 600;
            line-height: 1;
            width: 70px;
            position: relative;
            top: -3px;

            @include media("<medium") {
              font-size: rem-calc(70);
              top: -5px;
              width: 38px;
            }
          }
        }

        p {
          font-size: rem-calc(27);
          font-weight: 500;
          line-height: 1.51;
          margin-bottom: 0;
          //letter-spacing: 0.38px;
          color: $black;

          @include media("<medium") {
            font-size: rem-calc(18);
            line-height: 1.55;
          }
        }
      }

      p {
        font-size: rem-calc(18);
        font-weight: 500;
        line-height: 1.667;
        color: $black;
        margin-bottom: 29px;

        @include media("<medium") {
          font-size: rem-calc(16);
          line-height: 1.625;
          margin-bottom: 22px;
        }
      }

      h2 {
        font-weight: 600;
        font-size: rem-calc(20);
        line-height: 1.5;
        color: $grey;
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        margin-top: 10px;
      }
    }

    .sidebar-content {
      grid-column: 8 / 5 span;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #DEDDD5;
      align-items: baseline;

      @include media("<medium") {
        grid-column: 1 /12 span;
        border-left: unset;
        border-top: 1px solid #DEDDD5;
        padding-block: 40px 40px;
        padding-inline: 36px;
      }

      > picture {
        @include media("<medium") {
          display: none;
        }
      }

      .contactperson {
        margin-bottom: 146px;
        position: relative;
        left: 5px;
        top: 100px;

        @include media(">=xlarge") {
          left: 100px;
        }

        @include media("<medium") {
          margin-bottom: unset;
          display: flex;
          flex-direction: column;
          gap: 25px;
          position: static;
        }

        h2 {
          font-size: rem-calc(28);
          font-weight: 500;
          text-transform: uppercase;
          color: #BAB9AC;
        }

        &__wrapper {
          display: flex;
          gap: 15px;

          @media only screen and (min-width: 768px) and (max-width: 1200px) {
            flex-direction: column;
            margin-bottom: 25px;
          }

          + .btn {
            top: 15px;
          }
        }

        picture {
          border-radius: 50%;
          width: 177px;
          height: 177px;
          overflow: hidden;
          display: block;
          margin-bottom: 24px;

          @include media("<medium") {
            width: 100px;
            height: 100px;
            margin-bottom: 0;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-height: 177px;
          padding: 10px 0;

          > div {
            display: flex;
            flex-direction: column;
          }

          h3 {
            font-weight: 600;
            font-size: rem-calc(22);
            line-height: 1.667;
            color: $black;
            margin-bottom: 0;
            @include media("<medium") {
              font-size: rem-calc(16);
            }
          }

          span {
            font-weight: 400;
            font-size: rem-calc(22);
            line-height: 1.667;
            color: $black;
            //letter-spacing: 0.25px;
            @include media("<medium") {
              font-size: rem-calc(16);
            }
          }

          a {
            position: relative;

            &:first-of-type {
              margin-bottom: 15px;
            }
 
            &:before, &:after {
              content: '';
              width: 100%;
              height: 1px;
              position: absolute;
              bottom: -7px;
              left: 0;
              right: 0;
              background: $footer-title;
            }

            &:after {
              width: 0;
              background: $orange;
              transition: width;
            }

            &:hover, &:focus {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
