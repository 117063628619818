@use "sass:map";

.image-four-grid {
  padding-block: 75px 100px;
  background: #F4F4F1;
  border-left: 1px solid $white-grey;
  border-bottom: 1px solid #DEDDD5;

  @include media("<medium") {
    padding-block: 50px 50px;
  }

  .container {
    grid-column: 2 / 10 span;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
  }
  
  h2 {
    color: #BAB9AC;
    text-transform: uppercase;
    font-size: 60px;
    font-weight: 500;
    width: 50%;

    @include media("<medium") {
      font-size: 48px;
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    gap: 30px;

    @include media("<medium") {
      flex-direction: column;
    }
  }
    
  &__wrapper-left, &__wrapper-right {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__wrapper-left {
    margin-top: 50px;

    .image-four-grid__wrapper-item {
      &:nth-child(2) {
        @include media(">medium") {
          margin-left: 100px;
        }
      }
    }
  }

  &__wrapper-right {
    .image-four-grid__wrapper-item {
      &:nth-child(1) {
        @include media(">medium") {
          margin-right: 100px;
        }
      }
    }
  }

  &__wrapper-item {
    position: relative;
    
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url('/assets/templates/images/image-four-grid-cover-img.png');
      background-repeat: no-repeat;
    }
  }

  &__wrapper-content {
    position: absolute;
    bottom: 50px;
    left: 50px;
    z-index: 5;

    @include media("<xlarge") {
      bottom: 25px;
      left: 25px;
    }

    h3 {
      font-size: 32px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;

      @include media("<large") {
        font-size: 24px;
      }
    }
    
    span {
      font-size: 18px;
      color: #DEDDD5;
      font-weight: 500;
    }
  }
}