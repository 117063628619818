@use "sass:map";

.page-intro-sidebar {
  background-color: $white-grey;


  .text {
    padding-block: 50px 0; //188px 0;
    grid-column: 2 / 5 span;

    @include media("<=large") {
      padding-top: 100px
    }

    @include media("<medium") {
      padding-inline: 16px;
      padding-block: 42px 42px;
    }

    @include media("<medium") {
      grid-column: 1 /12 span;
    }

    .breadcrumbs li {
      &:not(:last-child):after {
        filter: brightness(0) saturate(100%) invert(90%) sepia(4%) saturate(285%) hue-rotate(13deg) brightness(98%) contrast(94%);
      }

      a {
        color: #DEDDD5;
      }
    }

    .highlight-text {
      display: flex;
      gap: 51px;
      margin-bottom: 50px;
      margin-top: 15px;

      @include media("<medium") {
        gap: 28px;
        margin-bottom: 45px;
      }

      .hightlight-character {
        border-right: 2px solid $letter-brown;
        overflow: hidden;
        flex: 0 0 auto;
        max-height: 149px;
        position: relative;
        left: -5px;
        top: 10px;


        @include media("<=large") {
          left: -1px;
          max-height: 80px;
        }

        .character {
          color: $letter-brown;
          font-size: rem-calc(130);
          font-weight: 600;
          line-height: 1;
          width: 70px;
          position: relative;
          top: -13px;

          @include media("<=large") {
            font-size: rem-calc(70);
            top: -5px;
            width: 38px;
          }
        }
      }

      p {
        font-size: rem-calc(27);
        font-weight: 500;
        line-height: 1.51;
        margin-bottom: 0;
        //letter-spacing: 0.38px;
        color: $black;

        @include media("<=large") {
          font-size: rem-calc(18);
          line-height: 1.55;
        }
      }
    }

    p {
      font-size: rem-calc(18);
      font-weight: 500;
      line-height: 1.667;
      color: $black;
      //letter-spacing: 0.25px;
      margin-bottom: 30px;

      @include media("<medium") {
        font-size: rem-calc(16);
        line-height: 1.625;
        margin-bottom: 22px;
      }

      a {
        font-weight: 500;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      margin-top: 2px;

      li {
        font-size: rem-calc(18);
        font-weight: 500;
        line-height: 1.667;
        color: $black;
        position: relative;
        padding-left: 1.875rem;

        &:before {
          content: '';
          position: absolute;
          top: 13px;
          left: 0;
          width: 11px;
          height: 10px;
          background-image: url("/assets/templates/images/svg/icon-list-item.svg");
          background-size: contain;
          background-repeat: no-repeat;
        }

        a {
          font-weight: 500;
        }
      }
    }

    h2 {
      font-weight: 600;
      font-size: rem-calc(20);
      line-height: 1.5;
      color: $grey;
      margin-bottom: 0;
    }

    .btn-alt {
      display: inline-block;
      margin-top: 47px;

      @include media("<medium") {
        margin-top: 39px;
      }
    }
  }

  .sidebar {
    grid-column: 8 /5 span;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid #DEDDD5;
    align-items: baseline;
    position: relative;
    left: -17px;

    @include media("<=global-width") {
      position: static;
      grid-column: 7 / 5 span;
    }

    @include media("<medium") {
      grid-column: 1 /12 span;
      border-left: unset;
      border-top: 1px solid #DEDDD5;
      padding-block: 40px 40px;
      padding-inline: 16px;
    }

    .sidebar-content {
      position: sticky;
      margin-left: 209px;
      top: 10px;
      margin-top: 185px;

      @include media("<=global-width") {
        margin-left: 100px;
      }

      @include media("<=1300px") {
        padding-left: 50px;
        margin-top: 185px;
        //position: static;
        margin-left: unset;
        top: 10px;
      }

      @include media("<=large") {
        margin-top: 100px
      }

      @include media("<medium") {
        margin-top: 0;
        padding-left: 0;
        position: static;
      }
    }

    h2 {
      color: $title-grey;
      font-size: rem-calc(28);
      line-height: 1;
      font-weight: 400;
      font-family: "astoria", sans-serif;
      text-transform: uppercase;

      @include media("<medium") {
        font-size: rem-calc(22);
      }
    }

    .contactperson {
      //margin-bottom: 146px;
      //position: relative;
      //left: 138px;
      display: flex;
      gap: 52px;
      margin-top: 45px;
      position: relative;
      //left: -5px;
      margin-bottom: 60px;

      @include media("<=large") {
        gap: 25px;
      }

      @include media("<medium") {
        margin-top: 30px;
        margin-bottom: 40px;
      }

      picture {
        border-radius: 50%;
        width: 177px;
        height: 177px;
        overflow: hidden;
        display: block;
        background-color: #DEDDD5;
        //margin-bottom: 24px;
        flex: 0 0 auto;

        @include media("<=large") {
          width: 100px;
          height: 100px;
          //margin-bottom: 0;
        }
      }

      .info {
        margin-top: 5px;

        h3 {
          font-weight: 600;
          font-size: rem-calc(22);
          line-height: 1.36;
          color: $black;
          margin-bottom: 0;
          @include media("<medium") {
            font-size: rem-calc(16);
          }
        }

        span {
          font-weight: 400;
          font-size: rem-calc(22);
          line-height: 1.36;
          color: $black;
          //letter-spacing: 0.25px;
          @include media("<medium") {
            font-size: rem-calc(16);
          }
        }

        ul {
          list-style: none;
          margin: 0px;
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          margin-left: 3px;
          gap: 15px;

          li {
            a {
              @include link();
            }

            &:last-child {
              a {
                font-size: rem-calc(17);
              }
            }
          }
        }
      }
    }
  }
}

.page-sierhekwerken {
  .page-intro-sidebar {
    padding-bottom: 100px;

    @include media("<medium") {
      padding-bottom: 40px;
    }
  }
}
