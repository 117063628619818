@use "sass:map";

.home-banner {
    background-size: cover;
    height: 90vh;
    width: 100%;
    position: relative;
    z-index: 4;
    background-position: center;
    margin-top: -8px;
    @include parallax-bg();

    @include media("<medium") {
        padding-top: 114px;
    }

    //@include media("<=large") {
    //  padding-top: 208px;
    //}

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/assets/templates/images/svg/home-banner-overlay.svg");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;

        @include media("<medium") {
            background-image: url("/assets/templates/images/svg/home-banner-mobile-overlay.svg");
        }
    }

    .row {
        margin-left: unset;
        margin-right: unset;

        @include media("<medium") {
            height: 100%;
        }
    }

    @include media("<medium") {
        .small-collapse {
            padding-inline: 0;
            display: flex;
            flex-direction: column;
        }
    }

    .content {
        position: absolute;
        // top: 380px;
        left: 138px;

        @media (min-width: 768px) {
            top: 50%;
        }

        @include media("<=large") {
            left: 50px;
            // top: 160px;
        }

        @include media("<medium") {
            position: static;
            flex: 1 1 auto;
            padding-inline: 16px;
            margin-top: 80px;
            z-index: 9;
        }
    }

    .text {
        span {
            font-weight: 500;
            font-size: em-calc(23);
            line-height: 1.304;
            color: rgba($white, 0.7);
            text-transform: uppercase;
            margin-bottom: 12px;
            display: inline-block;
            //letter-spacing: 0.4px;

            @media (min-width: 768px) and (max-width: 1920px) {
                margin-bottom: 1.5rem;
            }

            @include media("<medium") {
                font-size: em-calc(16);
                line-height: 1.25;

            }
        }

        h1 {
            font-family: "astoria", sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            font-size: em-calc(60);
            line-height: 1.2;
            max-width: 645px;
            margin-bottom: 3rem;
            color: $white;

            @media (min-width: 768px) and (max-width: 1920px) {
                font-size: 3.125em;
            }

            @include media("<medium") {
                font-size: em-calc(27);
                max-width: 360px;
                margin-bottom: 33px;
            }
        }

        p {
            font-size: em-calc(18);
            font-weight: 500;
            line-height: 1.667;
            color: $white;
            max-width: 552px;
            //letter-spacing: 0.25px;

            @include media("<medium") {
                font-size: em-calc(16);
                line-height: 1.43;
            }
        }
    }

    .google-review-mobile {
        background: rgba($black, 0.4);
        display: none; //flex
        justify-content: center;
        align-items: center;
        padding-block: 16px;
        z-index: 2;

        img {
            width: 21px;
            height: 20px;
            position: relative;
            left: -5px;
        }

        strong {
            font-size: rem-calc(18);
            line-height: 1.2778;
            font-weight: 600;
            color: $white;
            display: inline-block;
            //margin-left: 7px;
            //margin-right: 9px;
        }

        span {
            opacity: 0.6;
            color: $white;
            font-size: rem-calc(16);
            line-height: 1.25;
            display: inline-block;
            position: relative;
            right: -12px;
        }

    }

    .google-review {
        margin-top: 81px;
        //padding: 42px 47px 42px 32px;
        background: rgba($black, 0.4);
        display: inline-block;
        position: relative;
        top: 0;
        transition: top ease-in-out 0.3s;

        &:hover {
            top: -10px;
        }

        @include media("<medium") {
            display: none;
        }

        @include media("<=large") {
            margin-top: 40px;
        }

        @media (max-height: 1024px) {
            margin-top: 40px;
        }

        a {
            display: flex;
            gap: 55px;
        }

        .stars-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            ul {
                list-style: none;
                display: flex;
                margin: 0;
                position: relative;
                top: -5px;
            }
        }

        .review-text {
            h2 {
                font-weight: 600;
                font-size: em-calc(21);
                color: $white;
                letter-spacing: 0.45px;
                margin-bottom: -5px;
            }

            span {
                font-weight: 500;
                font-size: em-calc(21);
                color: #B2B2B2;
                //letter-spacing: 0.4px;
            }
        }
    }

    @include media('>medium') {
        .grid {
            position: absolute;
            bottom: 0;
            left: 0;
            //width: 100%;
        }
    }

    @include media(">1920px") {
        .grid {
            left: 50%;
            transform: translateX(-57.4%);
            width: 100%;
        }
    }

    .cta-buttons {
        min-height: 110px;
        grid-column: 9/4 span;
        display: flex;
        border-left: 1px solid transparent;

        @include media('<1400px') {
            flex-wrap: wrap;
        }

        @include media('<medium') {
            grid-column: 1/12 span;
            z-index: 1;
            flex-wrap: nowrap;
            min-height: auto;
        }

        .configure-gate {
            background-color: rgba($black, .83);
            width: 63%;
            padding: 22px 0 22px 30px;

            @include media('<1400px') {
                width: 100%;
            }

            @include media('<medium') {
                padding: 16px 10px 16px 15px;
                width: 66%;
            }

            .button-group {
                margin-bottom: 0;
                gap: 20px;
                margin-top: 10px;

                a {
                    &.link-icon {
                        color: $white;
                        text-transform: none;

                        @include media('<medium') {
                            font-size: rem-calc(14);
                            padding-left: 18px;
                        }

                        span {
                            display: block;
                            transition: translate .2s ease-in;
                        }

                        &:hover {
                            padding-left: 29px;

                            @include media('<medium') {
                                padding-left: 18px;
                            }

                            span {
                                translate: 6px 0;
                            }
                        }

                        &::before {
                            background-image: url(/assets/templates/images/svg/icon-menu.svg);
                        }
                    }
                }
            }
        }

        .request-offer {
            background-color: rgba($orange, .83);
            width: 40%;

            @include media('<1400px') {
                width: 100%;
            }

            @include media('<medium') {
                width: 34%;
            }

            a {
                width: 100%;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                padding-left: 55px;
                position: relative;
                overflow: hidden;

                @include media('<1600px') {
                    padding-left: 40px;
                }

                @include media('<1400px') {
                    padding-left: 56px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    justify-content: flex-start;
                }

                @include media('<medium') {
                    padding-left: 36px;
                }

                &:hover {
                    &::after {
                        translate: 0 0;
                    }
                }

                &::after {
                    content: "";
                    background-color: $orange;
                    position: absolute;
                    inset: 0;
                    z-index: -1;
                    translate: 0 100%;
                    transition: translate .2s ease-in;
                }

                h2 {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 10px;
                        left: -25px;
                        background-image: url(/assets/templates/images/svg/icon-menu.svg);
                        width: 12px;
                        height: 11px;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
        }

        h2 {
            font-weight: 700;
            font-size: rem-calc(20);
            text-transform: uppercase;
            color: $white;
            margin-bottom: 0;

            @include media(">=large", "<=xlarge") {
                font-size: rem-calc(20);
            }

            @include media("<medium") {
                font-size: rem-calc(14);
            }
        }
    }

    .poort-btn {
        position: absolute;
        //right: 0;
        bottom: 0;
        max-width: 536px;
        width: 100%;
        background-color: rgba($orange, .83);
        padding: 34px 50px 34px 50px;
        display: flex;
        gap: 39px;
        justify-content: space-between;
        align-items: center;
        left: 62%;

        @include media(">medium") {
            position: static;
            //left: unset;
            grid-column: 9/4 span;
        }

        @include media("<global-width") {
            right: 0;
            left: unset;
        }

        @include media("<=large") {
            grid-column: 7/6 span;
        }

        @include media("<medium") {
            position: static;
            max-width: unset;
            padding-inline: 16px;
            padding-block: 37px;
            gap: 0;
            z-index: 2;
            grid-column: 1/12 span;
        }

        .btn-text {
            display: flex;
            flex-direction: column;

            @include media("<medium") {
                max-width: 215px;
            }

            h2 {
                font-weight: 700;
                font-size: rem-calc(23);
                text-transform: uppercase;
                color: $white;
                margin-bottom: 0;

                @include media(">=large", "<=xlarge") {
                    font-size: rem-calc(20);
                }

                @include media("<medium") {
                    font-size: rem-calc(16);
                    margin-bottom: 7px;
                }
            }

            span {
                font-weight: 500;
                font-size: rem-calc(16);
                text-transform: uppercase;
                color: $white;
                line-height: 1.25;
            }
        }

        .icon-btn {
            width: 74px;
            height: 74px;
            border-radius: 50%;
            background-color: rgba($white, 0.3);
            position: relative;
            overflow: hidden;
            transition: all ease-in-out 0.3s;
            flex: 0 0 auto;

            @include media("<=xxlarge") {
                width: 62px;
                height: 62px;
            }

            @include media(">=large", "<=xlarge") {
                width: 45px;
                height: 45px;
            }

            @include media("<medium") {
                width: 62px;
                height: 62px;
                display: block;
            }

            &:before,
            &:after {
                content: '';
                background-repeat: no-repeat;
                background-size: contain;
                width: 74px;
                height: 74px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
                background-image: url('/assets/templates/images/svg/icon-btn-white.svg');
                transition: all ease-in-out 0.3s;

                @include media("<medium") {
                    width: 62px;
                    height: 62px;
                    display: block;
                }
            }

            &:after {
                background-image: url('/assets/templates/images/svg/icon-btn-yellow.svg');
                left: -50%
            }
        }

        &:hover,
        &:focus {
            .icon-btn {
                background-color: $white;

                &:before {
                    left: 150%
                }

                &:after {
                    left: 50%;
                }
            }
        }
    }

    .scroll-btn {
        @include media(">medium") {
            display: none;
        }

        width: 100%;
        background: $white;
        padding-block: 11px;
        //display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        display: none;

        span:not(.arrows) {
            font-weight: 500;
            color: $black;
            font-family: "astoria", sans-serif;
            font-size: rem-calc(14);
            line-height: 1.14;
            text-transform: uppercase;
            margin-right: 12px;
        }

        .arrows {
            position: relative;
            width: 24px;
            height: 24px;
            display: inline-block;

            &:before,
            &:after {
                content: '';
                width: 16px;
                height: 7px;
                display: block;
                background-size: contain;
                background-image: url("/assets/templates/images/svg/chevron-down-black.svg");
                background-repeat: no-repeat;
                left: 0;
                right: 0;
                position: absolute;
                animation: arrowsAni 2s infinite;
            }

            &:after {
                animation: arrowsAni 2s infinite;
                animation-delay: 0.5s;
            }
        }
    }
}

@keyframes arrowsAni {
    0% {
        opacity: 0;
        top: 0px;
    }

    50% {
        opacity: 1;
        top: 10px;
    }

    100% {
        opacity: 0;
        top: 20px;
    }
}

@keyframes arrowsAniSecond {
    0% {
        opacity: 0;
        top: 30px;
    }

    50% {
        opacity: 1;
        top: 40px;
    }

    100% {
        opacity: 0;
        top: 50px;
    }
}