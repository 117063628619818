@use "sass:map";

.configurator-layout {
  padding-block: 79px 75px;

  @include media("<=large") {
    padding-block: 74px 74px;
  }

  @include media("<medium") {
    padding-block: 65px 51px;
  }

  .container {
    grid-column: 2/11 span;
    display: grid;
    grid-template-columns: minmax(200px, 674px) minmax(200px, 674px);
    //grid-template-rows: 1fr 1fr;
    grid-auto-rows: minmax(10px, 722px) minmax(10px, 1fr);
    gap: 20px;
    justify-content: space-between;
    align-items: flex-start;

    @include media("<=large") {
      grid-column: 1/12 span;
    }

    @include media("<medium") {
      grid-template-columns: 1fr;
      grid-auto-rows: auto auto auto;
    }

    @include pim();

    @include media("<medium") {
      flex-direction: column;
      justify-content: unset;
    }

    .sidebar {
      grid-column: 1;
      grid-row: 1;

      &:nth-of-type(2) {
        //background: red;
        grid-row: 2;

        @include media("<medium") {
          grid-row: 3;
        }
      }

      h1 {
        font-size: rem-calc(45);
        text-transform: uppercase;
        font-family: "astoria", sans-serif;
        font-weight: 400;
        line-height: 1.33;
        color: $title-grey;
        margin-top: 17px;
        margin-bottom: -15px;

        @include media("<medium") {
          font-size: rem-calc(28);
          margin-top: 3px;
          margin-bottom: unset;
        }
      }

      .pictures {
        @include media("<medium") {
          max-width: calc(100vw - 50px);
        }

        .swiper-preview {
          overflow: hidden;
        }

        .overview {
          margin-top: 45px;
          //display: flex;
          //gap: 29px;

          @include media("<medium") {
            margin-top: 20px;
            //display: flex;
            //gap: 17px;
            width: 100%;
          }
        }
      }

      .information {
        margin-top: 39px;

        @include media("<=large") {
          margin-top: 0;
        }

        @include media("<medium") {
          margin-top: 33px;
        }

        h2 {
          font-size: rem-calc(18);
          text-transform: uppercase;
          color: $black;
          font-weight: 700;
          line-height: 1.27;

          @include media("<medium") {
            margin-bottom: 7px;
          }
        }

        ul {
          list-style: none;
          margin: 0;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          gap: 3px;

          @include media("<medium") {
            margin: 0;
            gap: 20px
          }

          li {
            font-size: rem-calc(16);
            font-weight: 500;
            line-height: 1.667;
            color: $black;
            position: relative;
            padding-left: 1.875rem;

            @include media("<medium") {
              padding-left: 24px;
              line-height: 1.25;
              max-width: 320px;
            }

            &:before {
              content: '';
              position: absolute;
              top: 10px;
              left: 0;
              width: 11px;
              height: 10px;
              background-image: url("/assets/templates/images/svg/icon-list-item.svg");
              background-size: contain;
              background-repeat: no-repeat;

              @include media("<medium") {
                top: 5px;
              }
            }

            a {
              font-weight: 500;
            }
          }
        }

        .btn-download {
          margin-top: 47px;

          @include media("<medium") {
            margin-top: 50px;
          }
        }
      }
    }

    .options-container {
      background: $white;
      padding: 44px 20px 50px 50px;
      max-width: 674px;
      width: 100%;
      grid-column: 2;
      grid-row: 1 /2 span;

      @include media("<=large") {
        padding: 45px 20px 50px 30px;
      }

      @include media("<medium") {
        padding: 45px 20px 50px 19px;
      }

      @include media("<medium") {
        grid-column: 1;
        grid-row: 2;
      }

      h2 {
        font-family: "astoria", sans-serif;
        font-size: rem-calc(28);
        font-weight: 500;
        line-height: 1.24;
        letter-spacing: 0.56px;
        color: $black;

        @include media("<medium") {
          font-size: rem-calc(23);
          line-height: 1.21;
        }

        + span {
          font-weight: 500;
          color: $black;
          font-size: rem-calc(18);
          line-height: 1.667;
          display: block;
          margin-top: 13px;


          @include media("<medium") {
            font-size: rem-calc(16);
            line-height: 1.875;
            margin-top: 6px;
          }
        }

      }

      .option {
        display: grid;
        grid-template-columns: fit-content(460px) 1fr;
        margin-top: 34px;
        margin-bottom: 34px;

        @include media("<medium") {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto auto;
          margin-top: 30px;
          margin-bottom: 30px;
          //row-gap: 20px;
        }

        &:not(:first-of-type) {
          @include media("<medium") {
            border-top: 1px solid #EFEFEF;
          }

          h3 {
            border-top: 1px solid #EFEFEF;
            padding-top: 30px;
            margin-bottom: 0px;

            @include media("<medium") {
              border: unset;
            }
          }
        }

        h3 {
          grid-column: 1/2 span;
          font-size: rem-calc(21);
          font-weight: 600;
          line-height: 1.29;
          color: $black;
          margin-bottom: 21px;

          @include media("<medium") {
            font-size: rem-calc(18);
            line-height: 1.27;
            grid-column: 1;
            grid-row: 1;
            margin-bottom: 30px;
          }
        }

        .chosen-value {
          @include media("<medium") {
            grid-column: 2;
            grid-row: 1;
            display: flex;
            justify-content: flex-end;
            margin-top: 5px;
          }

          span {
            max-width: 80px;
            font-size: rem-calc(14);
            font-weight: 600;
            line-height: 1.28;
            color: rgba(#464612, 0.35);
            position: relative;
            top: 25px;
            left: 26px;
            display: block;
            text-transform: uppercase;

            @include media("<=large") {
              position: static;
              max-width: unset;
            }
            @include media("<medium") {
              position: static;
              max-width: unset;
            }
          }
        }

        .values {
          @include media("<medium") {
            grid-column: 1/ 2 span;
          }
        }

        &.colors {

          .values {
            display: flex;
            gap: 30px 11px;
            flex-wrap: wrap;
            position: relative;
            left: -13px;

            @include media("<medium") {
              left: 0;
              gap: 19px 0;
            }

            .more-btn {
              display: none;
              flex: 1 1 100%;
              font-weight: 500;
              font-size: rem-calc(15);
              line-height: 1.27;
              color: $black;
              opacity: 0.65;
              position: relative;
              padding-block: 9px 3px;

              @include media("<medium") {
                display: block;
              }

              img {
                margin-left: 9px;
              }

            }

            label {
              display: flex;
              flex-direction: column;
              max-width: 80px;
              width: 100%;
              align-items: center;

              @include media("<medium") {
                width: 78px;
              }

              input {
                visibility: hidden;
                opacity: 0;
                position: absolute;

                + span {
                  width: 47px;
                  height: 47px;
                  position: relative;
                  border-radius: 50%;
                  margin: 0 .5rem 1rem 0;
                  &:after {
                    content: '';
                    position: absolute;
                    inset: -5px;
                    border: 2px solid transparent;
                    border-radius: 50%;
                  }
                }

                &:checked {
                  + span {
                    &:after {
                      border-color: $orange;
                    }
                  }
                }
              }

              span:not(.colour-code) {
                font-weight: 500;
                font-size: rem-calc(15);
                line-height: 1.26;
                text-align: center;
                position: relative;
                left: -3px
              }
            }

          }

        }

        &.choices {
          .values {
            display: flex;
            gap: 16px 18px;
            flex-wrap: wrap;

            @include media(">=medium", "<=large") {
              gap: 16px 6px;
            }

            @include media("<medium") {
              gap: 4px 15px;
              margin-top: 4px;
            }

            label {
              display: flex;
              flex-direction: column;
              align-items: center;
              max-width: 95px;

              input {
                width: 0;
                height: 0;
                appearance: none;

                &:checked + picture img {
                  border-inline: 1px solid $orange;
                  border-color: $orange;
                }
              }

              img {
                border: 1px solid #E2E2E2;
              }

              span {
                font-weight: 500;
                font-size: rem-calc(15);
                line-height: 1.26;
                text-align: center;
                padding-top: 16px;
                display: inline-block;

                @include media("<medium") {
                  padding-top: 14px;
                }

              }
            }
          }

          &.three-columns {
            .chosen-value {
              @include media("<medium") {
                align-items: flex-end;
              }

              span {
                top: 73px;
                @include media("<medium") {
                  position: static;
                }
              }
            }

            &.uitvoering {
              h3 {
                margin-bottom: 0;
              }

              .values {
                @include media("<medium") {
                  margin-top: 14px;
                }

                label {
                  max-width: 130px;

                  @include media("<medium") {
                    max-width: 94px;
                  }

                  span {
                    font-size: rem-calc(15);
                    line-height: 1.07;
                  }
                }
              }
            }
          }

          &.four-columns {
            .values {
              @include media("<medium") {
                gap: 4px 10px;
                margin-top: 7px;
              }

            }

            label {
              @include media("<medium") {
                max-width: 70px;
              }
            }

            .chosen-value {
              @include media("<medium") {
                align-items: flex-end;
              }

              span {
                top: 73px;
                left: 55px;

                @include media("<medium") {
                  position: static;
                }
              }
            }
          }
        }

        &.selects {
          display: block;

          h3 {
            margin-bottom: 17px;
            @include media("<medium") {
              margin-bottom: 27px;
            }
          }

          &.exact {
            @include media("<=xlarge") {
              .values {
                flex-direction: column;
              }
            }
          }

          .values {
            display: flex;
            width: 100%;
            gap: 20px;
            max-width: 574px;
            justify-content: space-between;

            @include media("<medium") {
              flex-direction: column;
              justify-content: unset;
              gap: 52px;
            }

            .form-group {
              flex: 1 1 50%;

              @include media("<medium") {
                flex: 1 1 100%;
              }

              + .form-group {
                margin-top: 0;
              }

              .form-control--description {
                display: block;
                font-size: 1rem;
                line-height: 1.56;
                color: rgba(30, 30, 28, .45);
                font-style: italic;
                font-weight: 500;
                margin-top: 6px;
              }
            }
          }

          + .buttons {
            @include media(">=medium", "<=large") {
              margin-top: 50px;
            }
          }
        }
      }

      .radio-buttons {
        display: flex;
        flex-direction: column;
        gap: 13px;
        margin-top: 40px;

        label {
          position: relative;

          input {
            appearance: none;
            width: 0;
            height: 0;
            position: absolute;

            &:before {
              background-color: #F2F2F2;
              width: 20px;
              height: 20px;
              display: block;
              border-radius: 50%;
              content: '';
              position: relative;
              top: 3px;
            }

            &:checked:before {
              background-color: $orange;
            }
          }

          span {
            font-size: rem-calc(18);
            font-weight: 500;
            line-height: 1.39;
            color: $black;
            padding-left: 36px;

            @include media("<medium") {
              font-size: rem-calc(16);
            }
          }
        }

        + option.selects {
          @include media(">large") {
            //Exception because for some reason the browser doesn't want to catch this.
            margin-top: 27px !important;
          }

        }
      }

      .form {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        margin-top: 30px;
        padding-right: 30px;

        @include media("<medium") {
          margin-top: 28px;
          padding-right: 0;
          row-gap: 24px;
        }

        .form-group {
          + .form-group {
            @include media("<medium") {
              margin-top: 0;
            }
          }

          &:nth-of-type(1),
          &:nth-of-type(8),
          &:nth-of-type(9) {
            flex: 1 1 100%;
          }

          &:nth-of-type(4) {
            flex: 1 1 calc(75% - 10px);
            max-width: 453px;
          }

          &:nth-of-type(5) {
            flex: 1 1 calc(25% - 10px);
            max-width: 101px;

            &.has-value {

              input {
                padding-right: 0;
              }

              label {
                display: none;
              }
            }
          }

          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(6),
          &:nth-of-type(7) {
            flex: 1 1 calc(50% - 10px);

            @include media("<medium") {
              flex: 1 1 100%;
            }
          }

          &.has-error {
            border-left-color: red;
            border-bottom-color: red;
          }
        }
      }

      .btn-prev {
        color: $black;
        background: transparent;
        padding-inline: 24px 26px;


        &:before {
          background-image: url('/assets/templates/images/svg/icon-btn-back.svg');
          left: 0;
        }

        &:hover, &:focus {
          &:before {
            background-image: url('/assets/templates/images/svg/icon-btn-back-hover.svg');
          }
        }
      }

      .btn {
        margin-top: 50px;

        @include media("<medium") {
          font-size: rem-calc(16);

          &-prev {
            padding-inline: 5px 0;
            width: 143px;
          }
        }
      }

      .buttons {
        @include media("<medium") {
          display: flex;
          gap: 5px;
          //padding-inline: 9px 0;
          justify-content: space-between;
        }
      }
    }
  }

  &.maatwerk {

    @include media("<medium") {
      .information {
        display: none;
      }
    }

    .container {
      .sidebar:nth-of-type(2) {
        button {
          display: none;
        }
      }

      @include media("<medium") {
        .sidebar:nth-of-type(2) {
          border-top: unset;
          padding: 0;
          margin: 0;
          position: fixed;
          bottom: 20px;
          left: 0;
          right: 0;
          padding-inline: 16px;
          width: 100%;
          z-index: 99;

          button {
            background-color: $orange;
            text-transform: uppercase;
            font-weight: 600;
            font-size: rem-calc(14);
            letter-spacing: 0.28px;
            color: $white;
            line-height: 1.29;
            position: relative;
            width: 100%;
            padding: 17px 22px 16px 20px;
            text-align: left;

            &::before {
              content: '';
              position: absolute;
              top: 21px;
              right: 19px;
              height: 13px;
              width: 21px;

              background-image: url("/assets/templates/images/svg/chevron-down-white.svg");
              background-repeat: no-repeat;
            }
          }
        }
      }

      .options-container {
        padding: 44px 40px 50px 50px;

        @include media("<medium") {
          padding: 35px 20px 50px 20px
        }

        h2 {
          + span {
            line-height: 1.389;
            margin-top: 14px;
          }
        }

        .option {
          border-top: unset;
          margin-top: 34px;
          margin-bottom: 30px;

          &:not(:first-of-type) {
            margin-top: 30px;

            @include media("<medium") {
              margin-top: 19px;
            }
          }

          &.colors {
            grid-template-columns: 1fr minmax(20px, 154px);

            @include media("<=large") {
              grid-template-columns: fit-content(460px) 1fr;
            }

            .values {
              gap: 14px 6px;

              label {
                &.custom-color {
                  input {
                    &:after {
                      background-image: url("/assets/templates/images/svg/custom-icon.svg");
                      background-repeat: no-repeat;
                      background-position: center;
                    }
                  }
                }
              }
            }

            .own-color {
              margin-left: -1px;
              grid-column: 1/2 span;
              margin-top: 15px;
              display: none;

              &.show {
                display: block;
              }

              p {
                font-size: rem-calc(18);
                line-height: 1.667;
                color: $black;
                font-weight: 500;
                margin-bottom: 15px;
              }

              + .form-group {

              }
            }
          }

          &.four-columns {
            margin-top: 16px;

            grid-template-columns: 1fr 1fr;
            border-bottom: 1px solid #EFEFEF;
            padding-bottom: 30px;

            &[data-table="verlichting"] {
              padding-bottom: 50px;
            }

            h3 {
              grid-column: 1;
              grid-row: 1;
              margin-bottom: 0;
            }

            .chosen-value {
              grid-column: 2;
              grid-row: 1;
              justify-self: flex-end;

              span {
                top: 6px;
                left: unset;
                right: 11px;
                max-width: unset;

              }
            }

            .values {
              grid-column: 1/ 2 span;
              grid-row: 2;

              label {
                max-width: 130px;

                @include media("<medium") {
                  max-width: 95px;
                }
              }
            }
          }

          &.checkboxes {
            display: block;

            h3 {
              margin-bottom: 12px;
            }

            span {
              font-size: rem-calc(18);
              font-weight: 500;
              line-height: 1.39;
              color: $black;
              //padding-left: 36px;

              @include media("<medium") {
                font-size: rem-calc(16);
              }
            }

            .values {
              margin-top: 31px;

              > label {
                position: relative;
                margin-bottom: 12px;

                input {
                  appearance: none;
                  width: 0;
                  height: 0;
                  position: absolute;

                  &:before {
                    background-color: #F2F2F2;
                    width: 20px;
                    height: 20px;
                    display: block;
                    border-radius: 0;
                    content: '';
                    position: relative;
                    top: 3px;
                  }

                  &:checked:before {
                    background-color: $orange;
                  }
                }

                span {
                  font-size: rem-calc(18);
                  font-weight: 500;
                  line-height: 1.39;
                  color: $black;
                  padding-left: 36px;

                  @include media("<medium") {
                    //font-size: rem-calc(16);
                  }
                }

                + .form-group {
                  margin-top: 34px;

                  //&.textarea {
                  //  min-height: 144px;
                  //
                  //  textarea {
                  //    min-height: 144px;
                  //  }
                  //}

                  //+ .form-group {
                  //  margin-top: 34px;
                  //}
                }
              }
            }

            + .buttons {
              .btn {
                margin-top: 0;
              }
            }
          }

          h3 {
            border-top: unset;
            padding-top: 0;
            margin-bottom: 21px;

          }
        }

        .btn {
          margin-top: 14px;
        }


        .form {
          margin-top: 33px;
          padding-right: 10px;

          @include media("<medium") {
            margin-top: 30px;
          }

          + .buttons {
            .btn {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  &__display-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    
    &:has(img) {
      margin-bottom: 25px;
      margin-top: -25px;
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  }
}

body.view-config-info {
  .configurator-layout.maatwerk .container .sidebar:nth-of-type(2) {
    .information {
      display: block;
      background: $white;

      h2 {
        font-size: rem-calc(18);
        line-height: 1.27;
        letter-spacing: 0.36px;
      }
    }

    .view-configuration {
      &:before {
        transform: rotate(-180deg);
        top: 17px
      }
    }
  }
}

.form-control--wrapper--checkbox:has(#homeImpression) {
  transform: translate(0, -50px);
  display: inline-block;
  padding: 15px 10px 0 0;
  background: #F4F4F1;
}