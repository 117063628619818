@use "sass:map";

.person-quote {
  padding-block: 46px 82px;
  background: $white;
  border-left: 1px solid $white-grey;

  @include media("<=large") {
    padding-block: 40px 50px;
  }

  @include media("<medium") {
    padding-block: 30px;
  }

  @include pim();

  .quote-container {
    grid-column: 4/7 span;
    background-color: $orange;
    background-image: url("/assets/templates/images/background-quote.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    padding: 34px 70px;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include media("<=xxlarge") {
      grid-column: 3/9 span;
    }

    @include media("<=large") {
      grid-column: 1/12 span;
      padding: 30px 50px;
    }

    @include media("<medium") {
      padding: 30px 30px;
    }

    @include media("<480px") {
      flex-direction: column;
      position: static;
      justify-content: unset;
      gap: 20px;
      padding-block: 30px 0;
    }

    figure {
      max-width: 402px;

      @include media("<medium") {
        max-width: 50%;
      }

      @include media("<480px") {
        max-width: unset;
      }

      blockquote {
        font-size: rem-calc(21);
        font-weight: 700;
        line-height: 1.28;
        text-transform: uppercase;
        color: $white;
        margin: 0;
        padding: 0;
        border: unset;

        @include media("<medium") {
          font-size: rem-calc(18);
        }

        &:before {
          content: '“';
          margin-right: 5px;
        }

        &:after {
          content: '”';
          margin-left: 5px;
        }
      }

      figcaption {
        font-size: rem-calc(18);
        font-weight: 700;
        line-height: 1.28;
        text-transform: uppercase;
        color: rgba($black, 0.2);
        margin-top: 1rem;

        @include media("<medium") {
          font-size: rem-calc(16);
        }
      }
    }

    picture {
      position: absolute;
      right: 100px;
      top: -74px;

      @include media("<=1500px") {
        right: 50px;
      }

      @include media("<=large") {
        top: unset;
        right: 0;
        bottom: 0;
      }

      @include media("<480px") {
        position: static;
      }
    }
  }

  + .centered-text {
    padding-top: 0;

    @include media(">=large") {
      padding-bottom: 150px;
    }
  }
}
