.page-content {
  background-color: $white;

  .site-container {
    background-color: $white-grey;
  }

  section:first-child {
    position: relative;
    top: 50px;
    max-height: 681px;
  }

  .pagetitle {
    @include media(">=large") {
      margin-top: 0;

      .medium-12 {
        h1 {
          margin-top: 34px;
        }
      }
    }
  }

  .page-banner {
    padding-bottom: 0;

    .parallax-banner {
      @include media(">=large") {
        height: 400px;
      }
    }
  }

  .header {
    .header__wrapper {
      &::before {
        content: unset;
      }
    }
  }

  .home-banner {
    .cta-buttons {
      display: none;
    }
  }

  .page-intro-sidebar {
    margin-top: 75px;
    padding-bottom: 75px;

    @media (max-width: 767px) {
      margin-top: 50px;
      padding-bottom: 50px;
    }

    .text ul li {
      padding-left: 0;

      &:before {
        content: unset;
      }
    }
  }

  .form-afspraak-maken {
    overflow: hidden;
    padding-block: 100px 100px;

    @include media("<medium") {
        padding: 50px 0;;
    }

    .form-afspraak-maken-container {
      .page-info {
        margin-top: 0;
      }
    }
    
    .breadcrumbs {
      display: none;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .form > h2 {
      color: #000;
    }
  }
}

