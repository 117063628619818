.page-projecten-overzicht, .page-project-detail {
  background-color: $white;

  .site-container {
    background-color: $white-grey;
  }

  section:first-child {
    position: relative;
    top: 35px;
  }
}

