.page-basispoort {
  background-color: $white;

  .site-container {
    background-color: $white-grey;
  }

  section:first-child {
    position: relative;
    top: 35px;
  }

  .project-images + .sidebar-form {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 27px;
      width: 100%;
      top: 0;
      left: 0;
      background-image: url("/assets/templates/images/svg/lijntje-parallax-banner.svg");
      background-size: cover;
      background-repeat: no-repeat;

      @include media("<medium") {
        //top: 20px;
        left: 18px;
        width: calc(100% - 18px);

      }
    }
  }

  .sidebar-form {
    @include media(">large") {
      padding-block: 129px 150px;
      .grid {
        &::before {
          top: -40px
        }
      }
    }

    .sidebar-content {
      margin-top: 4px;

      .contactperson {
        left: 0;
        margin-top: 44px;
      }
    }

    @include media("<medium") {
      padding-block: 82px 121px;


      .sidebar-content {

        h2 {
          font-size: rem-calc(21);
          line-height: 1.52;
          margin-bottom: 18px;
        }

        .contactperson {
          margin-top: 30px;
        }
      }

      .form {
        display: none;
      }
    }
  }
}
