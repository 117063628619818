@use "sass:map";

.blogs-overview {
  padding-block: 24px 30px;

  .blogs-container {
    grid-column: 2 / 11 span;
    display: grid;
    grid-template-columns: 1fr;

    @include media("<medium") {
      grid-column: 2 / 10 span;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media(">large") {
      width: 85%;
    }

    h2 {
        margin-bottom: 50px;
    }

    p {
        margin-bottom: 25px;
    }
  }

  &__item {
    border-top: 1px solid rgb(186,185,172, .25);
    border-bottom: 1px solid rgb(186,185,172, .25);
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 25px 0 25px 50px;
    
    @include media("<large") {
      grid-template-columns: 1fr;
      padding: 25px 0;
    }
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    position: relative;

    @include media("<large") {
      margin-top: 25px;
      justify-content: start;
    }

    img {
      width: 100%;
    }
  }
  
  &__image-label {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #BAB9AC;
    padding: 15px 20px 15px 10px;

    span {
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .pagination {
    grid-column: 2/11 span;
    padding-block: 100px 119px;

    @include media("<=large") {
      grid-column: 1/ 12 span;
      padding-inline: $mobile-padding-inline;
      padding-block: 50px 70px;
    }

    @include media("<medium") {
      padding-block: 30px 50px;
    }

    ul {
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: center;
      gap: 40px;
      flex-wrap: wrap;

      @include media("<=large") {
        gap: 20px;
      }

      li {
        a {
          font-size: rem-calc(24);
          line-height: 1;
          font-weight: 500;
          text-transform: uppercase;
          padding-bottom: 12px;
          border-bottom: 1px solid transparent;
          position: relative;
          width: 35px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          letter-spacing: 0.48px;
          left: 18px;

          @include media("<=large") {
            position: static;
          }

          &.is-active {
            font-weight: 600;
            border-bottom: 1px solid $orange;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 0;
            height: 1px;
            background-color: $black;
            transition: width ease-in-out 0.3s;
          }

          &:hover, &:focus {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}