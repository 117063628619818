@use "sass:map";

.configurator-navigator {
  padding-block: 41px 0;
  border-bottom: 1px solid #E9E8E3;

  @include media("<medium") {
    padding-block: 75px 0;
  }

  &.all-active {
    position: relative;

    &:before {
      content: '';
      border-bottom: 2px solid $orange;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      position: absolute;
    }
  }

  .container {
    grid-column: 1/12 span;

    .steps {
      list-style: none;
      margin: 0;
      display: flex;

      li {
        padding-block: 0 11px;
        position: relative;
        padding-right: 178px;
        padding-left: 57px;

        @include media("<medium") {
          padding-right: 25px;
          padding-left: 57px;
        }

        &.active {
          &:before {
            border-bottom: 2px solid $orange;
          }
        }

        &:first-child {
          padding-left: 138px;

          @include media("<medium") {
            padding-left: 15px;
          }
        }

        &:before {
          content: '';
          border-bottom: 2px solid transparent;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          position: absolute;
        }

        span:not(.title) {
          font-size: rem-calc(18);
          line-height: 1.27;
          font-weight: 700;
          text-transform: uppercase;
          color: $black;
          display: block;
          margin-bottom: 1px;

          @include media("<medium") {
            font-size: rem-calc(16);
            line-height: 1.25;
          }
        }

        .title {
          font-size: rem-calc(16);
          line-height: 1.25;
          font-weight: 500;
          color: $black;

          @include media("<medium") {
            font-size: rem-calc(14);
            line-height: 1.29;
          }
        }
      }
    }
  }

  &.maatwerk {
    .container {
      //overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }


      .steps {
        width: max-content;

        li {
          padding-right: 204px;

          @include media("<medium") {
            padding-right: 50px;
          }

          &:first-child {
            @include media("<medium") {
              padding-left: 17px;
            }
          }

          &:not(:first-child) {
            padding-inline: 38px 213px;

            @include media("<medium") {
              padding-inline: 54px 43px;
            }
          }

          &:last-child {
            padding-right: 0;
          }

          &.active {
            @include media("<medium") {
              //Only use :has for mobile. It's not supported in Firefox yet.
              &:has(+.active) {
                display: none;
              }
            }

          }
        }
      }
    }
  }
}