.page-homepage {
  background-color: $white;
  position: relative;
  margin-top: -194px;
  .header {
    .header__logo {
      opacity: 0 !important;
      .dark {
        display: none;
      }
  
      .light {
        display: block;
      }
    }


    &:not(:has(.header__popup > span)) .shield {
      top: 100px;
    }

    .shield {
      top: 140px;
  
      @include media("<=large") {
        top: 28px;
      }
    }
  
    .header__nav ul li a,
    .header__nav .header-review-button .google-review strong {
      color: #fff;
    }
  
    .header__nav .header-review-button .google-review strong i {
      color: rgb(255,255,255,.5);
    }
  
    .header__wrapper {
      position: relative;
  
      &:before {
        content: unset;
      }
    }
  }

  @include media(">large","<=1300px") {
      margin-top: -208px
  }

  @include media("<medium") {
    margin-top: -114px;
  }

  .header {
    @include media(">=large") {
      padding-block: 49px;
      top: 145px;
    }
  }

  .header__logo {
    animation: slideInLogo 1s;
    opacity: 0;
    animation-fill-mode: forwards;
  }

  .header__nav {
    animation: slideinMenu 1s;
    animation-delay: 1s;
    opacity: 0;
    animation-fill-mode: forwards;
  }

  .home-banner .content {
    animation: slideInContent 1s;
    animation-delay: 2s;
    opacity: 0;
    animation-fill-mode: forwards;

    @include media("<=large") {
      animation: slideInContentTablet 1s;
      animation-fill-mode: forwards;
    }


  // @media (max-height: 1200px) {
  //     display: flex;
  //     align-items: end;
  // }

    @media (max-height: 1024px) {
      animation: slideInContentTablet 1s;
      animation-fill-mode: forwards;
    }

  }

}

@keyframes slideInLogo {
  0% {
    left: -100%;
    opacity: 0;
  }

  100% {
    @include media(">=large") {
      left: -35px;
    }
    opacity: 1;
  }
}

@keyframes slideinMenu {
  0% {
    top: -100%;
    opacity: 0;
  }

  100% {
    top: 194px;
    opacity: 1;
  }
}

@keyframes slideInContent {
  0% {
    top: 100%;
    opacity: 0;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
}

@keyframes slideInContentTablet {
  0% {
    top: 100%;
    opacity: 0;
  }

  100% {
    top: 50%;
    opacity: 1;
  }
}

