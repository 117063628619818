.page-werkwijze {
  background-color: $white;

  section:first-child {
    position: relative;
    top: 35px;
  }

  .pagetitle {
    @include media(">=large") {
      margin-top: 18px;

      .medium-12 {
        h1 {
          margin-top: 34px;
        }
      }
    }
  }

  .form-afspraak-maken {
    overflow: hidden;
    
    .breadcrumbs {
      display: none;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .form > h2 {
      color: #000;
    }
  }

}
