@charset "utf-8";

@import 'variables';
@import 'functions';
@import 'fonts';
@import 'breakpoints';
@import '~include-media/dist/include-media';
@import "global";
@import "buttons";
@import 'aos/dist/aos.css';
@import 'swiper/swiper-bundle.min.css';
@import '@splidejs/splide/dist/css/splide.min.css';
@import 'lightbox';

//Page
@import "./page-homepage";
@import "./page-projecten-overzicht";
@import "./page-content";
@import "./page-werkwijze";
@import "./page-contact";
@import "./page-brochure-aanvragen";
@import "./page-afspraak-maken";
@import "./page-inrijpoorten";
@import "./page-basispoort";
@import "./page-maatwerk";
@import "./page-blogs-overzicht";
@import "./page-blogs-detail";
@import "./page-offerte-aanvragen";

//Components
@import '../components/header/header';
@import '../components/footer/footer';
//@import '../components/breadcrumb/breadcrumb';
//@import '../components/slider/slider';
//@import '../components/popup/popup';
//@import '../components/card/card';
//@import '../components/video/video';
//@import '../components/card-overview/filters/filters';
//@import '../components/accordion/accordion';
//@import '../components/header-with-submenus/header-with-submenus';
@import '../components/home-banner/home-banner';
@import '../components/page-intro-homepage/page-intro-homepage';
@import '../components/page-intro-sidebar/page-intro-sidebar';
@import '../components/tabs-homepage/tabs-homepage';
@import '../components/cta-line/cta-line';
@import '../components/homepage-paragraph-content/homepage-paragraph-content';
@import '../components/gate-slider/gate-slider';
@import '../components/showtuin-section/showtuin-section';
@import '../components/project-spotlight/project-spotlight';
@import '../components/cta-magazine/cta-magazine';
@import "../components/page-title/page-title";
@import "../components/breadcrumb/breadcrumb";
@import '../components/project-filters/project-filters';
@import '../components/project-overview/project-overview';
@import '../components/page-banner/page-banner';
@import '../components/project-detail-intro/project-detail-intro';
@import '../components/project-images/project-images';
@import '../components/sidebar-form/sidebar-form';
@import '../components/related-projects/related-projects';
@import '../components/our-team/our-team';
@import '../components/centered-text/centered-text';
@import '../components/image-slider/image-slider';
@import '../components/person-quote/person-quote';
@import '../components/faq/faq';
@import '../components/image-with-text/image-with-text';
@import '../components/contact-form-sidebar/contact-form-sidebar';
@import '../components/brochure-form/brochure-form';
@import '../components/form-afspraak-maken/form-afspraak-maken';
@import '../components/header-inrijpoorten/header-inrijpoorten';
@import '../components/product-overview-filters/product-overview-filters';
@import '../components/configurator-navigator/configurator-navigator';
@import '../components/configurator-layout/configurator-layout';
@import '../components/title-intro-text/title-intro-text';
@import '../components/header__popup/header__popup';
@import '../components/header-new-menu/header-new-menu';
@import '../components/blogs-overview/blogs-overview';
@import '../components/page-intro-blogs-detail/page-intro-blogs-detail';
@import '../components/related-blogs/related-blogs';
@import '../components/full-width-image/full-width-image';
@import '../components/three-columns-cta/three-columns-cta';
@import '../components/logo-slider/logo-slider';
@import '../components/image-four-grid/image-four-grid';
@import '../components/tabs-image/tabs-image';
