@use "sass:map";

.tabs-image {
  position: relative;
  border-left: 1px solid $white-grey;
  background-color: #fff;

  .grid {
    @include grid();
    z-index: 2;
    position: relative;

    .tabs-container {
      grid-column: 2 / 5 span;
      padding-block: 100px 100px;
      transition: opacity ease-in-out 0.3s;
      transition-delay: 1s;

      opacity: 0;

      @include media("<medium") {
        padding-block: 50px 0;
        grid-column: 1 /12 span;
        padding-inline: 16px;
      }

      h2 {
        color: #BAB9AC;
        font-family: "astoria", sans-serif;
        font-size: rem-calc(60);
        line-height: 1;
        max-width: 752px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 31px;

        @include media("<medium") {
          font-size: rem-calc(32);
          line-height: 1.19;
          max-width: 270px;
          margin-bottom: 21px;
        }
      }

      p {
        color: #000;
      }

      .accordion {
        background: unset;
        border: unset;

        .accordion-item {
          border: unset;
          border-bottom: 1px solid rgba($white, 0.5);
          padding-block: 32px 22px;

          @include media("<medium") {
            padding-block: 28px 27px;
          }

          .accordion-title {
            font-size: rem-calc(23);
            line-height: 1.30;
            color: #000;
            text-transform: uppercase;
            font-weight: 600;
            border: unset;
            padding: 0;
            margin-bottom: 7px;
            width: 90%;

            @include media("<medium") {
              font-size: rem-calc(18);
              line-height: 1.27;
              padding-right: 70px;
              margin-bottom: 0;
              width: 100%;

              &:before {
                right: 1rem !important;
              }
            }

            &:hover, &:focus {
              background: unset;
            }


            &::before {
              content: '';
              mask-image: url('/assets/templates/images/svg/arrow-tabs.svg');
              background-color: #BAB9AB;
              background-size: contain;
              background-repeat: no-repeat;
              width: 21px;
              height: 21px;
              right: -35px;
              transform: translateY(-50%);
              margin: 0;
              transition: all ease-in-out 0.3s;

              @include media("<medium") {
                width: 20px;
                height: 20px;
                transform: translateY(-50%) rotate(270deg);
              }
            }
          }

          &.is-active {
            .accordion-title {
              &:before {
                transform: translateY(-50%) rotate(90deg);
                background-color: $orange;
              }
            }
          }

          .accordion-content {
            background: unset;
            border: unset;
            padding: 0;

            @include media("<medium") {
              padding-top: 7px;
            }

            p {
              font-size: rem-calc(18);
              font-weight: 400;
              line-height: 1.667;
              color: #000;
              margin-bottom: 0;

              @include media("<medium") {
                font-size: rem-calc(16);
                line-height: 1.375;
              }
            }
          }

        }
      }
    }
  }

  &.isInView {
    &:before {
      left: -145px;

      @include media("<medium") {
        left: 0;
      }
    }

    .tabs-container {
      opacity: 1;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 7/6 span;

    @include media("<medium") {
      grid-column: 1 /12 span;
    } 
  }
}