@use "sass:map";

.tabs-homepage {
  position: relative;
  border-left: 1px solid $white-grey;


  .grid {
    @include grid();
    z-index: 2;
    position: relative;
    max-height: 858px;

    .tabs-container {
      grid-column: 2 / 4 span;
      padding-block: 134px 130px;
      transition: opacity ease-in-out 0.3s;
      transition-delay: 1s;

      opacity: 0;

      @include media("<medium") {
        padding-block: 80px 81px;
        grid-column: 1 /12 span;
        padding-inline: 16px;
      }

      h2 {
        color: $orange;
        font-family: "astoria", sans-serif;
        font-size: rem-calc(60);
        line-height: 1;
        max-width: 496px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 31px;

        @include media("<medium") {
          font-size: rem-calc(32);
          line-height: 1.19;
          max-width: 270px;
          margin-bottom: 21px;
        }
      }

      .accordion {
        background: unset;
        border: unset;

        .accordion-item {
          border: unset;
          border-bottom: 1px solid rgba($white, 0.5);
          padding-block: 32px 22px;

          @include media("<medium") {
            padding-block: 28px 27px;
          }

          .accordion-title {
            font-size: rem-calc(23);
            line-height: 1.30;
            color: $white;
            text-transform: uppercase;
            font-weight: 600;
            border: unset;
            padding: 0;
            margin-bottom: 7px;

            @include media("<medium") {
              font-size: rem-calc(18);
              line-height: 1.27;
              padding-right: 70px;
              margin-bottom: 0;
            }

            &:hover, &:focus {
              background: unset;
            }


            &::before {
              content: '';
              mask-image: url('/assets/templates/images/svg/arrow-tabs.svg');
              background-color: $white;
              background-size: contain;
              background-repeat: no-repeat;
              width: 21px;
              height: 21px;
              right: 0;
              transform: translateY(-50%);
              margin: 0;
              transition: all ease-in-out 0.3s;

              @include media("<medium") {
                width: 20px;
                height: 20px;
                transform: translateY(-50%) rotate(270deg);
              }
            }
          }

          &.is-active {
            .accordion-title {
              &:before {
                transform: translateY(-50%) rotate(90deg);
                background-color: $orange;
              }
            }
          }

          .accordion-content {
            background: unset;
            border: unset;
            padding: 0;

            @include media("<medium") {
              padding-top: 7px;
            }

            p {
              font-size: rem-calc(18);
              font-weight: 400;
              line-height: 1.667;
              color: $white;
              margin-bottom: 0;

              @include media("<medium") {
                font-size: rem-calc(16);
                line-height: 1.375;
              }
            }
          }

        }
      }
    }
  }

  .parallax-bg {
    background-image: url("/assets/templates/images/tabs-bg.jpg");
    height: 858px;
    width: 100%;
    @include parallax-bg();
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    background-image: url("/assets/templates/images/svg/triangle-home.svg");
    width: 1247px;
    height: 1451px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    left: -100%;
    transition: all ease-in-out 1s;

    @include media("<medium") {
      background-image: url("/assets/templates/images/svg/triangle-mobile-home.svg");
      //width: 100%;
      //height: 105%;
      left: 0;
      background-size: cover;
      width: 100%;
      height: 180vw;
      background-position: bottom;
    }
  }

  &.isInView {
    &:before {
      left: -145px;

      @include media("<medium") {
        left: 0;
      }
    }

    .tabs-container {
      opacity: 1;
    }
  }

}