@use "sass:map";

/**
Add the global SCSS/CSS here that should be available throughout the website
 */

:root {
  @each $name, $value in $root {
    --root-#{$name}: #{$value};
  }
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: var(--root-font-family);
  font-weight: 400;

  main {
    flex-grow: 1;
  }

  background-color: $white;

  .site-container {
    background-color: $white-grey;
  }

  .header-new-menu {
    background-color: $white;
  }

  .header__logo {
    .dark {
      display: block;
    }

    .light {
      display: none;
    }
  }

  // .header .shield {
  //   top: 90px;

  //   @include media("<=large") {
  //     top: 28px;
  //   }
  // }

  .header__nav ul li a,
  .header__nav .header-review-button .google-review strong {
    color: #0a0a0a;
  }

  .header__nav .header-review-button .google-review strong i {
    color: rgb(112, 112, 112, .5)
  }

  .header__wrapper {
    padding-bottom: 10px;
  }

  .pagetitle {
    padding-block: 75px 32px;
  }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--root-heading-font-family);
  font-weight: 700;
}

p, a {
  font-family: var(--root-font-family);
  font-weight: 400;
}

button {
  cursor: pointer;
}

$content-page-margin-bottom: (desktop: 140px, tablet: 70px, mobile: 45px);

main.content-page {
  section:last-of-type {
    margin-bottom: map.get($content-page-margin-bottom, desktop);

    //@include breakpoint(medium down) {
    @include media('<=large') {
      margin-bottom: map.get($content-page-margin-bottom, tablet);
    }

    //@include breakpoint(small down) {
    @include media('<medium') {
      margin-bottom: map.get($content-page-margin-bottom, mobile);
    }
  }
}

.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

.site-container {
  margin-left: 140px;
  position: relative;

  @include media("<xxlarge") {
    margin-left: 70px;
  }

  @include media("<=large") {
    margin-left: 0;
  }

  .link-icon-horse-desktop {
    position: absolute;
    bottom: 34px;
    left: -85px;
    width: 21px;
    height: 25px;
    mask-image: url("/assets/templates/images/svg/icon-horse-desktop.svg");
    background-color: #DEDDD5;
    font-size: 0;
    display: block;

    @include media("<=xxlarge") {
      left: -50px;
    }

    @include media("<=large") {
      display: none;
    }

    &:hover, &:focus {
      background-color: #EBAE09
    }
  }
}

.form-group {
  position: relative;
  border-left: 1px solid $form-border-color;
  border-bottom: 1px solid $form-border-color;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  max-height: 52px;

  + .form-group {
    margin-top: 21px;
    @include media("<medium") {
      margin-top: 25px;
    }
  }

  .form-control--description {
    display: none;
  }

  label {
    font-size: rem-calc(18);
    font-weight: 500;
    color: rgba($black, 0.25);
    position: absolute;
    bottom: 9px;
    left: 19px;
    right: unset;
    transition: all ease-in-out 0.3s;

    @include media("<medium") {
      font-size: rem-calc(16);
      bottom: 9px;
      left: 18px;
    }
  }

  input, textarea {
    padding: 14px 19px;
    color: $black;
    font-weight: 600;
    font-size: rem-calc(18);
    margin: 0;
    height: unset;
    box-shadow: unset;
    background: transparent;
    border: unset;
    width: 100%;
    padding-right: 122px;

    @include media("<medium") {
      font-size: rem-calc(16);
      padding: 14px 18px;
    }


    &:focus {
      background: transparent;
      border: unset;
      box-shadow: unset;
    }

    &:focus-visible {
      outline: unset;
    }

    &[type="file"] {
      color: transparent;

      &::-webkit-file-upload-button {
        display: none;
      }
    }
  }

  &.error {
    border-left: 1px solid $red !important;
    border-bottom: 1px solid $red !important;
  }

  &.has-value {
    border-left: 1px solid $orange;
    border-bottom: 1px solid $orange;

    label {
      right: 19px;
      left: unset;
      font-size: rem-calc(16);

      @include media("<medium") {
        font-size: rem-calc(14);
        right: 15px;
      }
    }

    input[type="file"] {
      color: $black;
    }
  }

  &.textarea {
    max-height: unset;
    min-height: 145px;

    @include media("<medium") {
      min-height: 107px;
    }

    label {
      bottom: unset;
      top: 9px;
    }

    textarea {
      min-height: 145px;
      @include media("<medium") {
        min-height: 107px;
      }
    }
  }

  &.checkboxes, &.radio {
    border: unset;

    > label {
      display: none;
    }

    label {
      font-size: rem-calc(18);
      font-weight: 600;
      line-height: 1.667;
      padding-inline: 27px;
      //height: 0;
      position: relative;
      top: 0;
      left: 0;
    }


    input {
      padding: 0;
      //width: auto;
      appearance: none;
      width: 0;


      &:before {
        content: '';
        width: 14px;
        height: 14px;
        display: block;
        position: absolute;
        top: 7px;
        left: 0;
        background-color: rgba($white, 0.08);
      }

      &:checked {
        &:before {
          background-color: $orange;
        }
      }
    }
  }

  &.radio {
    max-height: unset;

    > label {
      display: block;
      padding: 0;
      margin-bottom: 15px;
    }

    label:has(> input) {
      color: #000;
      padding-top: 4px;
    }

    input {
      &:before {
        border-radius: 50%;
        border: 1px solid #BAB9AB;
        height: 21px;
        width: 21px;
      }
    }
  }

  &.file {
    position: relative;
    
    &::after {
      background-image: url('/assets/templates/images/svg/icon-clip.svg');
      content: "";
      position: absolute;
      right: 12px;
      bottom: 16px;
      width: 11px;
      height: 21px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.with-icon {
    &.has-value {
      label {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 14px;
      bottom: 14px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &.icon-calendar {
      &::after {
        right: 9px;
        bottom: 17px;
        width: 20px;
        height: 18px;
        background-image: url('/assets/templates/images/svg/icon-calendar.svg');
      }
    }

    &.icon-clock {
      &::after {
        right: 12px;
        bottom: 16px;
        width: 18px;
        height: 18px;
        background-image: url('/assets/templates/images/svg/icon-clock.svg');
      }
    }

    &.icon-file {
      &::after {
        width: 11px;
        height: 21px;
        background-image: url('/assets/templates/images/svg/icon-file.svg');
        right: 10px;
        bottom: 15px;
      }
    }
  }

  &.select {
    border: unset;

    label {
      display: none;
    }

    select, .ui-selectmenu-button {
      height: 100%;
      max-height: 52px;
      padding: 16px 50px 16px 19px;
      border: 1px solid $form-border-color;
      font-weight: 500;
      font-size: rem-calc(18);
      line-height: 1.125;
      margin-bottom: 0;
      -webkit-appearance: none;
      appearance: none;
      background-image: url('/assets/templates/images/svg/chevron-down-black.svg');
      background-size: 12px 7px;
      background-position: top 9px right -32px;
      background-repeat: no-repeat;
      background-color: $white;
      border-radius: 0;
      width: 100%;
      position: relative;

      .ui-selectmenu-text {
        margin-right: unset;
      }

      .ui-icon {
        background-image: url('/assets/templates/images/svg/chevron-down-black.svg');
        width: 12px;
        height: 7px;
        background-position: unset;
        background-size: 12px 7px;
        //background-position: top 9px right -32px;
        background-repeat: no-repeat;
        position: absolute;
        top: 24px;
        right: 19px;
      }
    }

    .form-control--description {
      display: block;
      font-size: rem-calc(16);
      line-height: 1.56;
      color: rgba($black, 0.45);
      font-style: italic;
      font-weight: 500;
      margin-top: 6px;
    }
  }

}

.grid {
  @include grid();
}

p {
  font-size: rem-calc(18);
  font-weight: 400;
  line-height: 1.67;
  color: $black;

  a {
    text-decoration: underline;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}

.ui-widget {
  font-family: "bilo", sans-serif;
}

.ui-datepicker {
  border: unset;
  background: $white;
  border-radius: unset;

  .ui-datepicker-header {
    border-radius: unset;
    border: unset;
    background: unset;
    color: $black;
    font-weight: 600;
  }

  .ui-datepicker-prev span,
  .ui-datepicker-next span {
    background-image: url('/assets/templates/images/svg/arrow-back-black.svg');
  }

  th {
    font-weight: 600;
    text-transform: uppercase;
  }

  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: unset;
    background: unset;
  }

  .ui-state-default {
    font-weight: 500;

    &:hover, &:focus {

    }
  }

  .ui-state-active {
    color: $black;
    font-weight: 600;
    position: relative;
    z-index: 0;

    &:after {
      content: "";
      background: rgba($orange, 0.5);
      z-index: -1;
      border-radius: 50%;
      top: 0;
      left: 8px;
      right: -4px;
      bottom: 0;
      position: absolute;
    }
  }
}

.ui-widget-content {
  color: $black;
  font-weight: 500;
}

.ui-selectmenu-menu {
  .ui-selectmenu-menu {
    color: $black;
  }
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  background: rgba($orange, 0.35);
  border: 1px solid $white-grey;
  color: $black;
}

.rc-anchor,.grecaptcha-badge {
  opacity: 0 !important;
  pointer-events: none !important;
  visibility: hidden !important;
  display: none !important;
}

@mixin button-red() {
  background-color: #7b190f;

  &::before {
    background-image: url('/assets/templates/images/svg/btn-icon-red-dark.svg');
  }

  &:hover {
    background-color: #66140C;

    &::before {
      background-image: url('/assets/templates/images/svg/btn-icon-red-hover.svg');
    }
  }
}
.page-project-detail {
  .sidebar-form .form button {
    @include button-red();
  }
}

.page-contact {
  form button[type="submit"] {
    @include button-red();
  }
}

//Windows 10 zoom problems
@media (-webkit-device-pixel-ratio: 1.25) {
  html {
    font-size: 80%
  }
}

a:not([href]) {
  cursor: auto;
}