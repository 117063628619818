@use "sass:map";

.contact-form-sidebar {
  padding-block: 0 148px;

  @include media("<=large") {
    padding-bottom: 100px;
  }

  @include media("<medium") {
    padding-bottom: 30px;
  }

  .container {
    grid-column: 2/11 span;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include pim();
    @include media("<=large") {
      grid-column: 1/12 span;
    }

    @include media("<medium") {
      flex-direction: column-reverse;
      justify-content: unset;
    }

    .form-navigation {
      display: none;
    }
    
    .sidebar {
      max-width: 552px;
      display: flex;
      flex-direction: column;

      @include media("<=large") {
        max-width: 300px;
      }

      @include media("<medium") {
        max-width: unset;
      }

      > picture {
        overflow: hidden;
        //position: relative;

        .parallax-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }


      .showtuin {
        background-color: $form-border-color;
        background-repeat: no-repeat;
        background-image: url("/assets/templates/images/svg/dodewaard-showtuin.svg");
        background-position: bottom right;
        padding: 44px 50px;
        flex: 1 1 auto;

        @include media("<=large") {
          padding: 30px 40px
        }

        @include media("<medium") {
          padding: 35px 20px 200px
        }

        h2 {
          color: $white;
          font-family: "astoria", sans-serif;
          font-size: rem-calc(32);
          text-transform: uppercase;
          font-weight: 500;
          display: inline-block;
          margin-bottom: 64px;
          line-height: 1.09;

          @include media("<=large") {
            font-size: rem-calc(28);
          }

          @include media("<medium") {
            font-size: rem-calc(24);
          }
        }
      }
    }

    .contact-form {
      display: flex;
      flex-direction: column;
      max-width: 828px;
      width: 100%;

      .contact-info-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .contact-info {
          position: relative;
          top: 48px;

          @include media("<=medium") {
            top: 0;
          }

          @include media("<480px") {
           margin-top: 60px; 
          }

          h2 {
            font-weight: 600;
            font-size: rem-calc(22);
            line-height: 1.54;
            color: $black;
            margin-bottom: 0;

            @include media("<medium") {
              font-size: rem-calc(20);
            }
          }

          .info {
            display: flex;
            gap: 66px;

            @include media("<1400px") {
              gap: 30px;
            }

            @include media("<=medium") {
              flex-direction: column;
              gap: 20px;
            }
          }

          address {
            font-style: normal;
            font-weight: 400;
            font-size: rem-calc(22);
            line-height: 1.54;
            color: $black;
            white-space: nowrap;

            @include media("<1400px") {
              font-size: rem-calc(20);
            }
          }

          ul {
            list-style: none;
            margin: 0;
            display: flex;
            flex-direction: column;

            li {
              a {
                @include link();
                font-size: rem-calc(22);
                font-weight: 400;

                @include media("<1400px") {
                  font-size: rem-calc(20);
                }
              }

              &:last-child {
                a {
                  font-size: rem-calc(20);
                }
              }
            }
          }
        }

        .contact-person {
          position: relative;
          right: 60px;
          top: 5px;

          @include media("<=global-width") {
            right: 0;
          }

          @include media("<=large") {
            right: 0;
          }

          a {
            &:hover, &:focus {
              picture {
                transform: scale(0.9);
              }

              span {
                top: 2px;
                left: -125px
              }
            }
          }

          picture {
            border-radius: 50%;
            width: 145px;
            height: 145px;
            overflow: hidden;
            display: block;
            background-color: #DEDDD5;
            transform: scale(1);
            transition: all ease-in-out 0.3s;

            @include media("<=large") {
              width: 120px;
              height: 120px;
            }

            @include media("<medium") {
              width: 100px;
              height: 100px;
              //margin-bottom: 0;
            }
          }

          span {
            background-color: #BAB9AB;
            color: $white;
            font-weight: 600;
            font-size: rem-calc(18);
            line-height: 1.88;
            position: absolute;
            top: -3px;
            left: -131px;
            padding: 1px 25px 1px 21px;
            transition: all ease-in-out 0.3s;
            z-index: 1;
          }
        }
      }
    }

    .contact-form-opening-hours {
      margin-top: 40px;
      font-size: rem-calc(22);

      p {
        font-size: rem-calc(22);

        @include media("<medium") {
          font-size: rem-calc(18);
        }
      }
    }

    .contact-form-container {
      background-color: $white;
      padding: 48px 70px 50px 85px;
      margin-top: 45px; //105px;
      max-width: 828px;

      @include media("<=large") {
        padding: 30px 50px;
      }

      @include media("<medium") {
        padding: 30px;
      }

      h2 {
        font-size: rem-calc(25);
        font-weight: 400;
        line-height: 1.44;
        color: $brown;
        text-transform: uppercase;
        font-family: "astoria", sans-serif;
        margin-bottom: 33px;

        @include media("<medium") {
          font-size: rem-calc(21);
          margin-bottom: 25px;
        }
      }

      .form-group + .form-group {
        margin-top: 22px;
      }

      .btn-form {
        margin-top: 27px;
      }
    }
  }
}
