.page-brochure-aanvragen {
  background-color: $white;

  .site-container {
    background-color: $black;
  }

  .header {
    .header__logo {
      .dark {
        display: none;
      }
  
      .light {
        display: block;
      }
    }

    .shield {
      top: 35px;
  
      @include media("<=large") {
        top: 28px;
      }
    }

    .header__wrapper {
      &:before {
        content: unset;
      }
    }
  }
}
