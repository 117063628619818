@use "sass:map";

.cta-line {
  padding-block: 203px 52px;
  background: $white;

  @include media("<medium") {
    padding-block: 214px 52px;
  }

  .cta-line-container {
    grid-column: 2 / 5 span;


    @include media("<medium") {
      grid-column: 1 /12 span;
      padding-inline: 16px;
    }
  }

  span {
    position: relative;
    z-index: 2;
    font-size: rem-calc(42);
    font-weight: 500;
    line-height: 1.52;
    color: $white;
    background-image: linear-gradient(-64deg, transparent 31px, $brown 0);
    box-decoration-break: clone;
    //background-size: 16px 100%, 16px 100%, calc(100% - 32px) 100%;
    //background-position: left, right, center;
    //background-repeat: no-repeat;
    padding: 11px 68px 8px 32px;

    @include media("<medium") {
      font-size: rem-calc(24);
      line-height: 1.46;
      padding: 4px 31px 9px 9px;
      max-width: 310px;
    }

    @supports (-webkit-hyphens:none) and (-webkit-touch-callout: none) {
      box-decoration-break: unset;
    }
  }

  .grid {
    @include grid();
  }
}