@use "sass:map";

.project-detail-intro {
  border-left: 1px solid $white-grey;
  background: $white;
  padding-block: 242px 50px;
  //position: relative;
  margin-top: -216px;


  @include media("<=large") {
    padding-inline: $mobile-padding-inline;
    position: static;
    padding-block: 40px 50px;
    margin-top: 0;
  }

  @include media("<medium") {
    position: static;
    padding-block: 20px 40px;
  }

  .left-side {
    grid-column: 2 / 5 span;

    @include media("<=large") {
      grid-column: 1/6 span;
    }

    @include media("<medium") {
      grid-column: 1/12 span;
    }

    .location {
      font-family: "astoria", sans-serif;
      font-size: rem-calc(28);
      line-height: 2.14;
      color: $title-grey;
      text-transform: uppercase;
      font-weight: 400;

      @include media("<medium") {
        font-size: rem-calc(20);
      }

    }

    h1 {
      font-family: "astoria", sans-serif;
      font-weight: 400;
      font-size: rem-calc(60);
      line-height: 1;
      text-transform: uppercase;
      color: black;
      margin-bottom: 35px;

      @include media("<=large") {
        font-size: rem-calc(40);
        margin-bottom: 25px;
      }

      @include media("<medium") {
        font-size: rem-calc(28);
        margin-bottom: 20px;
      }
    }
  }

  .right-side {
    grid-column: 8 / 5 span;

    @include media("<=large") {
      grid-column: 7/6 span;
    }

    @include media("<medium") {
      grid-column: 1/12 span;
    }

    p {
      margin-top: 59px;
      font-weight: 500;

      @include media("<=large") {
        margin-top: 40px
      }

      @include media("<medium") {
        margin-top: 0px
      }
    }

    .btn-alt {
      margin-top: 30px;
      display: inline-block;

      @include media("<=large") {
        margin-top: 30px;
      }

      @include media("<medium") {
        margin-top: 25px;
      }
    }
  }
}