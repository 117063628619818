.page-afspraak-maken {
  background-color: $white;

  .site-container {
    background-color: $white-grey;
    background-image: url("/assets/templates/images/afspraak-maken-bg.png");
    background-size: cover;
    background-position: top right;
  }

  .header {
    .header__wrapper {
      &:before {
        content: unset;
      }
    }

    .shield {
      top: 35px;
  
      @include media("<=large") {
        top: 28px;
      }
    }
  }
}
