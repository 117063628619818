@use "sass:map";

.showtuin-section {
  background-size: cover;
  position: relative;
  display: flex;
  @include media(">large") {
    min-height: 860px;
  }

  @include parallax-bg();

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/assets/templates/images/red-overlay.png");
    width: 398px;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
    z-index: 1;

    @include media("<medium") {
      background-image: url("/assets/templates/images/svg/red-overlay-mobile.svg");
      width: 282px;
      height: 630px;
    }

    @include media(">1920px") {
      width: 26vw;
      background-size: 35vw 100%;
    }
  }

  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/assets/templates/images/svg/showtuin-shadow-overlay.svg");
    background-size: cover;

  }

  .grid {
    z-index: 2;
  }

  .info {
    grid-column: 2 / 2 span;
    position: relative;
    padding-block: 128px;
    z-index: 2;

    @include media("<medium") {
      grid-column: 2 /5 span;
      padding-block: 80px;
      left: 17px;
    }

    span {
      color: $red;
      font-size: rem-calc(24);
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.48px;

      @include media("<medium") {
        font-size: rem-calc(18);
        letter-spacing: 0.36px;
      }
    }

    h2 {
      font-size: rem-calc(38);
      line-height: 1.1;
      color: $white;
      font-weight: 500;
      letter-spacing: 0.76px;
      margin-top: 3px;
      margin-bottom: 61px;

      @include media("<medium") {
        font-size: rem-calc(32);
        line-height: 1.06;
        letter-spacing: 0.64px;
        margin-top: 11px;
        margin-bottom: 38px;
      }
    }

    .link-icon {
      color: $white;
      display: inline-block;
      margin-top: 50px;
      width: 100%;

      @include media("<medium") {
        margin-top: 52px;
      }

      &:before {
        background-image: url("/assets/templates/images/svg/btn-icon-red.svg");
      }
    }
  }

  .info-list {
    grid-column: 4 / 9 span;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: flex-end;

    @include media("<medium") {
      grid-column: 1/12 span;
      padding-inline: 16px;
    }


    ul {
      display: flex;
      justify-content: space-between;
      margin: 0;
      width: 100%;
      margin-bottom: 54px;

      @include media("<medium") {
        flex-direction: column;
        padding-top: 158px;
        align-items: center;
        gap: 52px;
        justify-content: unset;
        margin-bottom: 70px;
      }

      li {
        display: flex;
        gap: 24px;

        @include media("<medium") {
          //width: 100%;
        }

        img {
          position: relative;
          top: 5px;
        }

        &:last-child {
          p {
            max-width: 190px;

            @include media("<medium") {
              max-width: 242px;
            }
          }
        }
      }
    }

    p {
      font-weight: 400;
      line-height: 1.38;
      font-size: rem-calc(18);
      color: $white;
      max-width: 240px;

      @include media("<medium") {
        &:first-child {
          line-height: 1.2;
        }
        margin-bottom: 0;
      }

      strong {
        font-weight: 600;
      }
    }
  }
}