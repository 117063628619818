@use "sass:map";

.image-slider {
  background: $white;
  padding-block: 45px 8px;
  border-left: 1px solid $white-grey;
  overflow: hidden;


  @include media("<=large") {
    padding-block: 30px 20px;
  }

  @include media("<medium") {
    padding-block: 30px;
  }

  .image-slider-container {
    grid-column: 2 / 11 span;
    position: relative;

    @include media("<=large") {
      grid-column: 1/12 span;
    }

    @include pim();

    .splide {
      @include media(">1784px") {
        position: relative;
        width: calc(calc(100vw - 1784px) + 99%);
      }
    }
  }

}