@use "sass:map";

.product-overview-filters {
    padding-block: 22px 80px;

    @include media('<medium') {
        padding-block: 0 60px;
    }

    &.five-columns {
        .container {
            .product-overview {
                //width: calc(100% - 300px);
                grid-template-columns: repeat(5, 1fr);
                gap: 20px;

                @include media("<1600px") {
                    width: 100%;
                    grid-template-columns: repeat(4, 1fr);
                }

                @include media("<1400px") {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include media('<medium') {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include media("<560px") {
                    grid-template-columns: repeat(1, 1fr);
                }

                .product-overview-item {
                    padding: 27px;

                    picture {
                        height: 200px;
                        overflow: hidden;
                        top: auto;
                        right: auto;

                        @include media("<560px") {
                            height: auto;
                        }

                        img {
                            object-fit: cover;
                            height: 100%;
                        }
                    }

                    span {
                        h2 {
                            font-size: rem-calc(30);

                            @include media("<1920px") {
                                font-size: rem-calc(30);
                            }

                            @include media("<=xlarge") {
                                font-size: rem-calc(36);
                            }

                            @include media("<=large") {
                                font-size: rem-calc(32);
                            }
                        }
                    }
                }
            }
        }
    }

    &.three-columns {
        .container {
            .product-overview {
                width: calc(100% - 300px);
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                @include media("<1600px") {
                    width: 100%;
                }

                @include media("<1400px") {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include media("<560px") {
                    grid-template-columns: repeat(1, 1fr);
                }

                .product-overview-item {
                    padding: 27px;

                    picture {
                        height: 200px;
                        overflow: hidden;
                        top: auto;
                        right: auto;

                        @include media("<560px") {
                            height: auto;
                        }

                        img {
                            object-fit: cover;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .container {
        grid-column: 2/11 span;
        display: flex;
        justify-content: space-between;
        gap: 20px;

        @include media("<medium") {
            justify-content: unset;
            flex-direction: column-reverse;
        }

        @include pim();
        @include media("<=large") {
            grid-column: 1/12 span;
        }

        .filters-overview {
            display: flex;
            flex-direction: column;
            max-width: 300px;

            @include media("<medium") {
                flex-direction: row;
                max-width: unset;
                gap: 20px;
            }

            @include media("<480px") {
                flex-direction: column;
            }

            .filters {
                background: $orange;
                padding: 39px 84px 44px 42px;

                @include media('<medium') {
                    display: none;
                }

                h2 {
                    font-size: rem-calc(21);
                    font-weight: 700;
                    line-height: 1.29;
                    color: $white;
                    text-transform: uppercase;
                    margin-bottom: 18px;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 9px;

                    + h2 {
                        margin-top: 34px;
                        font-size: rem-calc(18);
                        line-height: 1.27;
                        position: relative;
                        left: -2px;
                        margin-bottom: 14px;
                    }

                    li {
                        &:not(:last-child) {
                            border-bottom: 1px solid rgba($black, 0.07);
                        }

                        label {
                            color: $white;
                            font-size: rem-calc(18);
                            line-height: 1.27;
                            font-weight: 500;
                            padding-left: 22px;
                            position: relative;
                            display: inline-block;
                            padding-bottom: 6px;

                            input {
                                width: 0;
                                height: 0;
                                appearance: none;

                                &:before {
                                    content: '';
                                    width: 15px;
                                    height: 15px;
                                    display: block;
                                    position: absolute;
                                    top: 3px;
                                    left: 0;
                                    background-color: rgba($white, 1);
                                }

                                &:checked {
                                    &:before {
                                        background-color: $black;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .explanation {
                margin-top: 52px;

                @include media("<medium") {
                    margin-top: unset;
                    width: 50%;
                }

                @include media("<480px") {
                    width: 100%;
                }

                .explanation-header {
                    display: flex;
                    margin-bottom: 6px;
                    gap: 12px;
                    align-items: flex-start;

                    img {
                        margin-top: 2px;
                    }

                    h2 {
                        font-size: rem-calc(18);
                        line-height: 1.27;
                        text-transform: uppercase;
                        font-weight: 700;
                        color: $black;
                    }
                }

                .text {
                    p {
                        font-weight: 500;
                        font-size: rem-calc(16);
                        line-height: 1.4375;

                        a {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .product-overview {
            display: grid;
            grid-template-columns: repeat(3, fit-content(340px));
            gap: 40px 40px;

            @include media("<1540px") {
                gap: 30px;
            }

            @include media("<=xxlarge") {
                gap: 35px;
                grid-template-columns: repeat(2, fit-content(340px));
            }

            @include media("<=large") {
                gap: 25px;
            }

            @include media("<480px") {
                gap: 20px;
                grid-template-columns: 1fr;
            }

            .product-overview-item {
                background: $white;
                padding: 51px 27px 57px 58px;
                transition: all ease-in-out 0.3s;
                display: flex;
                flex-direction: column;
                align-items: baseline;

                @include media("<=xlarge") {
                    padding: 40px 25px 40px 50px
                }

                @include media("<=large") {
                    padding: 25px;
                }

                &:hover, &:focus, &.hover {

                    .label {
                        top: 10px;
                        left: 10px;
                    }

                    .product-info {
                        margin-top: 45px;
                        top: 0;
                        left: 11px;

                        @include media("<=xlarge") {
                            margin-top: 25px;
                        }

                        @include media("<=large") {
                            margin-top: 20px;
                        }

                        .product-link {
                            top: -10px;
                        }
                    }

                    picture img {
                        transform: scale(0.97);
                    }
                }

                .label {
                    background: $black;
                    color: $white;
                    font-size: rem-calc(16);
                    line-height: 1.25;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 11px 20px 8px 19px;
                    position: relative;
                    top: 0;
                    left: 0;
                    transition: all ease-in-out 0.3s;

                    @include media("<=large") {
                        padding: 10px;
                        font-size: rem-calc(14);
                    }

                    img {
                        margin-right: 10px;
                        position: relative;
                        top: -3px;

                    }
                }

                picture {
                    position: relative;
                    top: 15px;
                    right: 26px;

                    @include media("<=large") {
                        position: static;
                    }
                    img {

                        transition: transform ease-in-out 0.3s;
                        transform: scale(1);
                    }

                }

                .product-info {
                    display: inline-block;
                    margin-top: 55px;
                    flex: 1 0 auto;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    top: 0;
                    left:0;
                    transition: all ease-in-out 0.3s;

                    @include media("<=xlarge") {
                        margin-top: 40px;
                    }

                    @include media("<=large") {
                        margin-top: 25px;
                    }

                    h2 {
                        font-size: rem-calc(36);
                        line-height: 1;
                        color: $black;
                        font-weight: 500;
                        letter-spacing: 0.94px;
                        flex: 1 0 auto;

                        @include media("<1540px") {
                            font-size: rem-calc(40);
                        }

                        @include media("<=xlarge") {
                            font-size: rem-calc(36);
                        }

                        @include media("<=large") {
                            font-size: rem-calc(32);
                        }
                    }

                    .product-link {
                        @include link-icon();
                        margin-top: 26px;
                        margin-left: 2px;
                        position: relative;
                        transition: all ease-in-out 0.3s;
                    }
                }
            }

            .pagination {
                grid-column: 1/2 span;
                padding-block: 55px 119px;

                @include media("<=large") {
                    padding-block: 50px 70px;
                }

                @include media("<medium") {
                    padding-block: 30px 50px;
                }

                @include media("<480px") {
                    grid-column: 1/1 span;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    gap: 40px;
                    flex-wrap: wrap;

                    @include media("<=large") {
                        gap: 20px;
                    }

                    li {
                        a {
                            font-size: rem-calc(24);
                            line-height: 1;
                            font-weight: 500;
                            text-transform: uppercase;
                            padding-bottom: 12px;
                            border-bottom: 1px solid transparent;
                            position: relative;
                            width: 35px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            letter-spacing: 0.48px;
                            left: -16px;

                            @include media("<=large") {
                                position: static;
                            }

                            &.is-active {
                                font-weight: 600;
                                border-bottom: 1px solid $orange;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                width: 0;
                                height: 1px;
                                background-color: $black;
                                transition: width ease-in-out 0.3s;
                            }

                            &:hover, &:focus {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
